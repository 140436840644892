.keycloackerror {
  width: 43%;
  text-align: left;
  margin: 70px auto 0;
  float: none;
}

.keycloackerror h1 {
  font-family: 'redHatBold';
  font-size: 36px;
  font-style: normal;
  line-height: normal;
  padding: 0;
  margin: 30px 0;
  color: red;
}

.keycloackerror h2 {
  color: #363636;
  font-family: 'redHatBold';
  font-size: 25px;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  font-style: normal;
  line-height: normal;
}

.keycloackerror h3 {
  color: #363636;
  font-family: 'redHatMedium';
  font-size: 25px;
  padding: 0;
  margin: 40px auto;
  font-weight: 300;
  font-style: normal;
  line-height: normal;
}

.keycloackerror ol {
  font-size: 15px;
}

.keycloackerror ol li {
  margin: 15px auto;
  font-size: 18px;
  font-family: 'redHatMedium';
}

.keycloackerror ol li span {
  font-size: 18px;
  font-weight: 800;
}

.keycloackerror .err-reload {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
}

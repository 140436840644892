.uppernav-menu-component .menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.uppernav-menu-component .menu-items {
  position: relative;
  width: 100%;
  user-select: none;
  line-height: normal;
  color: #90a3bf;
  cursor: pointer;
  column-gap: 10px;
  font-size: 12px;
  font-weight: 700;
  font-family: system-ui;
}

.uppernav-menu-component .menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.uppernav-menu-component .menu-items button {
  display: flex;
  align-items: center;
  border: none;
  justify-content: space-between;
  cursor: pointer;
  text-align: right;
  width: 100%;
  user-select: none;
  background: none;
  position: relative;
}

.uppernav-menu-component .menu-items button.topbtn {
  font-size: 15px;
  padding: 5px 5px;
  background: var(--themeColor);
  color: white;
  text-transform: uppercase;
  position: relative;
  border-radius: 7px;
  font-weight: bold;
  width: 35px;
  height: 35px;
  text-align: center;
  justify-content: center;
}

.uppernav-menu-component .menu-items button.topbtn.not-up-org {
  border: 2px solid red;
}

.uppernav-menu-component button span {
  margin-left: 3px;
}

.uppernav-menu-component .menu-items > a,
.uppernav-menu-component .menu-items button {
  text-align: right;
  padding: 8px 1rem;
}

.uppernav-menu-component .menu-items button.logout {
  justify-content: end;
}

.uppernav-menu-component .menu-items a:hover,
.uppernav-menu-component .menu-items button:hover {
  background-color: #f2f2f2;
}

.uppernav-menu-component .menu-items button.topbtn:hover {
  background-color: var(--themeColor);
}

.uppernav-menu-component .arrow::after {
  content: '';
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.uppernav-menu-component .dropdown {
  position: absolute;
  right: 0;
  user-select: none;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 16rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.uppernav-menu-component .dropdown.show {
  display: block;
}

.uppernav-menu-component .dropdown .dropdown-submenu {
  position: absolute;
  right: 100%;
  top: 0px;
}

.uppernav-menu-component .content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.uppernav-menu-component .content a {
  color: #cc3852;
  margin-right: 10px;
}

.uppernav-menu-component .disabled-link {
  pointer-events: none;
  opacity: 0.5;
}

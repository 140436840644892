.emly-btn-primary {
  background-image: none !important;
  border-radius: 6px;
  padding: 9px 15px;
  font-size: 13px;
  font-family: 'redHatBold';
  font-size: 100%;
  white-space: pre;
  /* min-width: 10em; */
  text-align: center;
}
.emly-btn-primary.block-button {
  display: none;
}
.emly-btn-primary[disabled] {
  background-color: #ffff !important;
  border: 1px solid #8f9692 !important;
  color: #b2b8b5 !important;
}
.emly-btn-primary.cancel {
  background-color: #fff;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  margin-right: 10px;
}
.emly-btn-primary.action {
  border: none;
  border-color: var(--themeColor);
  background-color: var(--themeColor);
  color: #fff;
}
.emly-btn-primary.action:hover {
  background-color: #2d3748;
}
.emly-btn-primary.success {
  background-color: #ffff;
  border: 1px solid #16ba60;
  color: #16ba60;
}
.emly-btn-primary.success.saved {
  background-color: #16ba60;
  border: 1px solid #16ba60;
  color: #ffff;
}
.emly-btn-primary.success.saved[disabled] {
  background-color: #16ba60 !important;
  border: 1px solid #16ba60 !important;
  color: #ffff !important;
}

.emly-btn-primary.error {
  border: 1px solid red;
  background-color: red;
  color: #ffff;
  padding: 6px 38px;
}
.emly-btn-primary.bordered {
  border: 1px solid var(--blackColor);
  background-color: #ffff;
  color: var(--blackColor);
  padding: 6px 32px;
}

.emly-btn-primary.help {
  color: #1a202c;
  border: none;
  background-color: #ffff;
  padding: 9px 20px;
  font-size: 15px;
}
.emly-btn-primary.help img {
  width: 15px;
  margin-bottom: 3px;
  margin-left: 5px;
}

/* add single scroll  design */
.scrollWithWidth {
  height: calc(100vh - 270px);
  overflow-y: auto;
  overflow-x: hidden;
}

/* scroll */
.customScrollDsgn::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.customScrollDsgn::-webkit-scrollbar-track {
  background: #ededed;
  border-radius: 20px;
}

/* Handle */
.customScrollDsgn::-webkit-scrollbar-thumb {
  background: var(--themeColor);
  border-radius: 20px;
}

/* Handle on hover */
.customScrollDsgn::-webkit-scrollbar-thumb:hover {
  background: var(--themeColor);
}
/* 
/* add single scroll  design smaller width*/

/* scroll */
.customScrollDsgn-wl::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.customScrollDsgn-wl::-webkit-scrollbar-track {
  background: var(--themeColor);
  border-radius: 20px;
}

/* Handle */
.customScrollDsgn-wl::-webkit-scrollbar-thumb {
  background: #ededed;
  border-radius: 20px;
}

/* Handle on hover */
.customScrollDsgn-wl::-webkit-scrollbar-thumb:hover {
  background: #ededed;
}
/*
main container */
.emly-mc {
  width: 100%;
  position: relative;
  float: left;
}

.alert-mesg-part {
  position: relative;
  float: left;
  width: 100%;
  padding: 2px 4px;
  background-color: #fcd462;
  text-align: center;
  font-family: 'redHatBold';
}
.alert-mesg-part.danger {
  position: relative;
  float: left;
  width: 100%;
  padding: 2px 25px;
  color: red;
  text-align: start;
  font-family: 'redHatBold';
  background-color: #fff;
  margin-bottom: 5px;
  white-space: pre;
}
.alert-mesg-part.danger .colm-nme {
  color: var(--blackColor);
}
.alert-mesg-part.danger .colm-nme:hover {
  background-color: var(--themeColor);
  color: #fff;
}
.emly-no-dt-fnd {
  position: relative;
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  font-family: 'redHatBold';
  font-size: 17px;
}

.emly-rd-txt {
  color: red;
  font-family: 'redHatBold';
  font-size: 12x;
  padding-left: 5px;
}

.organization-checkout {
  text-align: center;
  line-height: normal;
}

.organization-checkout .c-label {
  margin-bottom: 10px;
  font-family: 'redHatMedium';
  font-size: 15px;
}

.organization-checkout .c-org {
  margin: 0;
  font-family: 'redHatBold';
  font-size: 21px;
}

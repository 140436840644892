.acc-info-page-root {
  width: 100%;
  float: left;
  position: relative;
  margin-top: 12px;
}
.acc-info-page-root .scrollable-div-contents {
  height: calc(100vh - 246px) !important;
}

.acc-info-page-root .billing-information {
  width: 100%;
  position: relative;
  display: block;
  float: left;
}

.acc-info-page-root .billing-information .no-data-found-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.acc-info-page-root .billing-information .no-data-found-parent .no-data-gif {
  margin: 20px auto;
  width: 300px;
}

.acc-info-page-root .billing-information .no-data-found-parent .no-data-footer {
  font-size: 21px;
  font-family: system-ui;
  line-height: 28px;
  color: #84898f;
  font-weight: 500;
}

.acc-info-page-root .invite-button {
  float: right;
  width: 100%;
  text-align: right;
  position: relative;
  font-size: 15px;
}

/* 
adding name to top of page */
.acc-info-page-root .billing-information .filter-Container-form {
  position: relative;
  float: left;
  width: 100%;
  clear: both;
  padding: 10px 0px 20px;
}

.acc-info-page-root .billing-information .filter-Container-form .fieldset {
  position: relative;
  float: left;
  width: 100%;
}

.acc-info-page-root .billing-information .filter-Container-form .fieldset .form-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.acc-info-page-root .billing-information .filter-Container-form .fieldset .form-group label {
  width: 20%;
}

.acc-info-page-root .billing-information .filter-Container-form .fieldset .form-group select {
  width: 70%;
}

.acc-info-page-root .billing-information .filter-Container-form .fieldset .form-group input {
  width: 70%;
}

.acc-info-page-root .billing-information .filter-Container-form .fieldset .form-group span {
  width: 100%;
  float: left;
  text-align: right;
}

.acc-info-page-root .billing-information .filter-Container-form button {
  padding: 5px 15px;
  font-size: 12px;
  float: right;
  margin-right: 10%;
}

.acc-info-page-root .billing-information a {
  font-size: 19px;
  font-family: 'RedHatRegular';
  text-transform: capitalize;
  font-weight: 700;
  padding: 6px 61px;
  border-radius: 7px;
  color: #ffff;
  background-color: var(--themeColor);
  cursor: pointer;
}

.slider-coins {
  margin: 0 !important;
  padding: 0 !important;
  user-select: none;
  width: 100%;
  overflow: hidden;
  text-align: left;
  font-family: 'redHatBold';
}

.coins-buy .rangeslider-horizontal .rangeslider__fill {
  background-color: var(--themeColor) !important;
}

.coins-buy .rangeslider {
  background: #ffffff !important;
}

.coins-buy h3 {
  font-family: 'redHatBold';
  margin: 15px 0 !important;
}

.coins-buy .no-radius {
  border-radius: 0% !important;
}

.acc-info-page-root .no-data-found-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.acc-info-page-root .no-data-found-parent .no-data-gif {
  margin: 20px auto;
  width: 250px;
}

.acc-info-page-root .no-data-found-parent .no-data-footer {
  font-size: 21px;
  font-family: system-ui;
  line-height: 28px;
  color: #84898f;
  font-weight: 500;
}

#usagePage {
  float: left;
  position: relative;
  width: 100%;
}

/* 
style for conatiner metering page /usage page ACC to NEW Design f*/
.filter-Container-form#usage {
  position: relative;
  width: 100%;
}

/* add main scroll below filters  */
.filter-Container-form#usage .usage-page-body-content {
  height: calc(100vh - 200px);
  overflow-y: auto;
  position: relative;
  width: 100%;
  margin-right: 3px;
  padding: 0 10px 0px 0px;
  overflow-x: hidden;
}

.filter-Container-form#usage .usage-top-filters-container .drop-down-container-usage {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 15px;
}

/* drop down1 style */
.filter-Container-form#usage .usage-top-filters-container .drop-down-container-usage .Deloyment-type-selector {
  width: 40%;
  position: relative;
}

.filter-Container-form#usage .usage-top-filters-container .drop-down-container-usage .other-filter-container {
  position: relative;
  width: 48%;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  gap: 21px;
}

/* style label of seeclect bar */
.filter-Container-form#usage .usage-top-filters-container .drop-down-container-usage .select-bar-name {
  color: var(--themeColor);
  background-color: #ffff;
  z-index: 6;
  position: absolute;
  font-family: 'redHatMedium';
  font-size: 12px;
  padding: 0px 3px 0px 0px;
}

.filter-Container-form#usage
  .usage-top-filters-container
  .drop-down-container-usage
  .Deloyment-type-selector
  .select-bar-name {
  top: -26%;
  left: 2%;
}

.filter-Container-form#usage
  .usage-top-filters-container
  .drop-down-container-usage
  .Time-filter-selector
  .select-bar-name {
  top: -26%;
  left: 7%;
}

.filter-Container-form#usage
  .usage-top-filters-container
  .drop-down-container-usage
  .active-inactive-filter
  .select-bar-name {
  top: -26%;
  left: 7%;
}

/* drop down2 style */
.filter-Container-form#usage .usage-top-filters-container .drop-down-container-usage .Time-filter-selector {
  width: 30%;
  position: relative;
}

/* add custom design style to select boxes */
.filter-Container-form#usage
  .usage-top-filters-container
  .drop-down-container-usage
  .css-2b097c-container
  .css-yk16xz-control {
  border-radius: 11px;
}

.filter-Container-form#usage .usage-top-filters-container .drop-down-container-usage .btn {
  border: none;
  box-shadow: none;
  background: transparent;
  outline: none;
}

/* style the table  */
.filter-Container-form#usage .usage-page-body-content .srollable-usage-page-table-conatiner {
  width: 100%;
  /* height: calc(100vh - 473px);
  overflow: auto; */
  position: relative;
  cursor: pointer;
  user-select: none;
  margin-top: 2em;
  border-radius: 16px;
}
.filter-Container-form#usage .usage-page-body-content .srollable-usage-page-table-conatiner .tabl-cntr {
  width: 100%;
  height: calc(100vh - 460px) !important;
  overflow: auto;
  padding: 5px;
}

/* separate syle for table heading */
.filter-Container-form#usage .usage-page-body-content .srollable-usage-page-table-conatiner table {
  white-space: pre;
}

.filter-Container-form#usage .usage-page-body-content .srollable-usage-page-table-conatiner table th {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-family: 'redHatBold';
}

.filter-Container-form#usage .usage-page-body-content .srollable-usage-page-table-conatiner .table-label {
  /* padding: 0em 1em; */
  font-weight: 600;
  font-size: 22px;
  color: #2d3748;
}

.filter-Container-form#usage .usage-page-body-content .srollable-usage-page-table-conatiner table {
  border: 1px solid #ddd;
  text-align: left;
}

.filter-Container-form#usage .usage-page-body-content .srollable-usage-page-table-conatiner td {
  border: 1px solid #ddd;
  text-align: left;
}

.filter-Container-form#usage .usage-page-body-content .srollable-usage-page-table-conatiner th {
  border: 1px solid #ddd;
  text-align: left;
}

.filter-Container-form#usage .usage-page-body-content .srollable-usage-page-table-conatiner table {
  border-collapse: collapse;
  width: 100%;
  font-family: 'redHatBold';
}

.filter-Container-form#usage .usage-page-body-content .srollable-usage-page-table-conatiner th {
  padding: 10px;
}

.filter-Container-form#usage .usage-page-body-content .srollable-usage-page-table-conatiner td {
  padding: 10px;
}

/* show loader at middle */
.filter-Container-form#usage .processLoader {
  position: absolute;
  height: 100%;
  z-index: 1000;
  width: 100%;
  top: 30%;
}

/* style when  record no usage  found */
.filter-Container-form#usage .usage-page-body-content .no-usage-record-found-no-data {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  width: 100%;
  padding: 80px 0px 0px 0px;
  font-size: 20px;
  font-family: 'redHatBold';
}

/* style for cards and line chart */
.filter-Container-form#usage .usage-page-body-content .parent-for-Chart-and-Conatiner-Cards {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 1em;
  align-items: stretch;
  flex-grow: 1;
}

.filter-Container-form#usage .usage-page-body-content .line-chart-usage-page {
  /* overflow-wrap: break-word;  */
  user-select: none;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.9);
  margin-top: 4em;
  display: flex;
  position: relative;
  width: 100%;
}

.filter-Container-form#usage .usage-page-body-content .parent-for-Chart-and-Conatiner-Cards .conatiner-4-card-parent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 22px;
  padding: 2em 2em;
  border: 2px solid rgba(144, 163, 191, 0.3);
  border-radius: 15px;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .active-users-list-content {
  position: relative;
  width: 100%;
  font-family: 'redHatBold';
}
.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .active-users-list-content
  .act-ldr {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .row-of-cards {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: flex-start;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .conatiner-card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
  overflow-wrap: break-word;
  color: #2d3748;
  font-weight: 700;
  font-family: 'redHatBold';
}

/* change label size */
.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .conatiner-card
  .number-icon-parent
  .number {
  font-size: 18px;

  margin-right: 25px;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .conatiner-card
  .number-icon-parent
  span {
  align-self: flex-end;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .conatiner-card
  .number-icon-parent {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;

  flex-direction: row;

  justify-content: flex-start;
}

/* scroll for usage metering table if remove style of horizonatal scroll of table will chnage */
.filter-Container-form#usage .usage-page-body-content div.srollable-usage-page-table-conatiner::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.filter-Container-form#usage
  .usage-page-body-content
  div.srollable-usage-page-table-conatiner::-webkit-scrollbar-track {
  background: #ededed;
  border-radius: 20px;
}

/* Handle */
.filter-Container-form#usage
  .usage-page-body-content
  div.srollable-usage-page-table-conatiner::-webkit-scrollbar-thumb {
  background: var(--themeColor);
  border-radius: 20px;
}

/* Handle on hover */
.filter-Container-form#usage
  .usage-page-body-content
  div.srollable-usage-page-table-conatiner::-webkit-scrollbar-thumb:hover {
  background: var(--themeColor);
}

/* ask */
.scrollable-div-contents#usagePageLoading {
  pointer-events: none;
  user-select: none;
}

/* add style to chart scroll */

.filter-Container-form#usage .usage-page-body-content div.line-chart-usage-page::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.filter-Container-form#usage .usage-page-body-content div.line-chart-usage-page::-webkit-scrollbar-track {
  background: #ededed;
  border-radius: 20px;
}

/* Handle */
.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  div.line-chart-usage-page::-webkit-scrollbar-thumb {
  background: var(--themeColor);
  border-radius: 20px;
}

/* Handle on hover */
.filter-Container-form#usage .usage-page-body-content div.line-chart-usage-page::-webkit-scrollbar-thumb:hover {
  background: var(--themeColor);
}

/* hover effect */
/* .filter-Container-form#usage
  .usage-page-body-content
  
  div.line-chart-usage-page:hover {
  overflow-y: auto;
  overflow-x: auto;
} */

.filter-Container-form#usage .usage-top-filters-container .drop-down-container-usage .active-inactive-filter {
  width: 30%;
  position: relative;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .active-users-list-content
  .heading {
  color: #90a3bf;
  font-size: 15px;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .active-users-list-content
  .heading
  span {
  color: black;
  font-size: 12px;
}

/* style to tabs of container status */
/* style icons of card  */
.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .row-of-cards
  .card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .conatiner-card
  .label-of-card {
  color: #90a3bf;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .row-of-cards
  .card-icon
  .icon-file-div {
  padding-right: 1em;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent#active-users {
  padding: 1em 1em;
  width: 44%;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .list-of-user {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  height: 15em;
  /* to keep space between scrol and content */
  padding-right: 1em;

  display: flex;
  flex-direction: column;
}
.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .list-of-user
  .lst {
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .list-of-user
  .lst
  .fa-power-off {
  font-size: 20px;

  margin-left: 3px;
}
.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .list-of-user
  .per-user {
  width: 100%;
  position: relative;
  /* padding-top: 1em; */
  padding-left: 1em;
  cursor: pointer;
  margin-bottom: 8px;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .list-of-user
  .per-user
  .type-of-deployment-with-status {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 67px;
  gap: 67px;
  width: 100%;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .list-of-user
  .per-user
  .date-user-id {
  display: flex;
  align-items: flex-start;
  color: #90a3bf;
  /* padding: 0 1em; */
  padding-left: 1em;
  /* gap: 5em; */
  justify-content: space-between;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .list-of-user
  .per-user
  .date-user-id
  .fa {
  margin-right: 3px;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .list-of-user
  .per-user
  .d-type {
  font-weight: 400;
  font-size: 11px;
}
.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .list-of-user
  .per-user
  .d-type:hover {
  text-decoration: underline;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .list-of-user
  .per-user
  .type-of-deployment-with-status
  .d-status {
  font-size: 11px;
  font-family: 'redHatMedium';
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .list-of-user
  .per-user
  .type-of-deployment-with-status
  .d-status
  span {
  padding-left: 4px;
}

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  .list-of-user
  .per-user
  .type-of-deployment-with-status
  .d-status
  .fa {
  margin-right: 6px;
  color: #16ba60;
}

/* add style to chart scroll  for list-user inside active user card*/

.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  div.list-of-user::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  div.list-of-user::-webkit-scrollbar-track {
  background: #ededed;
  border-radius: 20px;
}

/* Handle */
.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  div.list-of-user::-webkit-scrollbar-thumb {
  background: #90a3bf;
  border-radius: 20px;
}

/* Handle on hover */
.filter-Container-form#usage
  .usage-page-body-content
  .parent-for-Chart-and-Conatiner-Cards
  .conatiner-4-card-parent
  div.list-of-user::-webkit-scrollbar-thumb:hover {
  background: #90a3bf;
}

.MemberListBox {
  flex-direction: row;
  display: flex;
  justify-content: center;
}

.project--div {
  flex-direction: column;
  display: flex;
  width: 100%;
}

.org-container {
  flex-direction: column;
  display: flex;
}

.user-lists {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.MemberListBox-box {
  width: 25%;
  background-color: white;
  border: 1px solid lightgrey;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.row-td {
  cursor: pointer;
  overflow: auto;
  /* height: 300px; */
}

.parent-row {
  border-bottom: 1px solid lightgrey;
  /* Set your desired border style here */
}

.fa .fa-trash .user {
  color: red;
  margin-left: 20px;
}

.memberlist-item-avatars-big {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  align-self: center;
  margin-bottom: -40px;
  margin-top: 30px;
  font-size: 30px;
  z-index: 1;
  background-color: var(--themeColor);
}

.child-table {
  width: 100%;
  border-collapse: collapse;
}

.memeberlistDiv-item-header {
  font-size: 11;
  color: grey;
  font-weight: bold;
}

.fa .fa-arrow-circle-up .user {
  color: var(--themeColor);
}

.fa .fa-arrow-circle-down .user {
  color: var(--themeColor);
}

.dataset-tabs-title-arrow {
  font-size: 9px;
  margin-top: 25px;
  color: grey;
  cursor: pointer;
  margin-right: 5px;
  padding-top: 3px;
}

.dataset-tabs-title {
  font-size: 12px;
  margin-top: 25px;
  color: gray;
  cursor: pointer;
  margin-right: 5px;
  font-weight: bold;
}

.dataset-tabs-title-sub {
  font-size: 12px;
  margin-top: 25px;
  font-weight: bold;
  color: var(--themeColor);
  font-weight: bold;
}

.top-row-title {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

/* Billing Popup CSS */

.billingpopup .popupMainContainer-all-content {
  padding: 0 !important;
}

.billingpopup .popupMainContainer-all-content .currentOrg {
  display: none;
}

.billingpopup .popupMainContainer-all-content .em-row.em-flex {
  align-items: unset !important;
  gap: 20px;
  flex-wrap: nowrap !important;
}

.billingpopup .popupMainContainer-all-content .em-row.em-flex .bg-img {
  width: 300px;
}

.billingpopup .popupMainContainer-all-content .modalFooter-1 {
  display: none !important;
}

.billingpopup .popupMainContainer-all-content .em-row.em-flex ul {
  padding: 0 0 0 15px;
  margin: 25px auto;
}

.billingpopup .popupMainContainer-all-content .try-Now .css-yk16xz-control .css-1uccc91-singleValue {
  color: #ffffff !important;
}

.billingpopup .popupMainContainer-all-content .em-row.em-flex .btnsection {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  padding: 15px 0px;
  width: 100%;
  float: left;
  justify-content: space-between;
}

.billingpopup .popupMainContainer-all-content div {
  margin-top: 0px !important;
}

.billingpopup .popupMainContainer-all-content .em-row.em-flex .btnsection a {
  text-decoration: underline;
}

.billingpopup .popupMainContainer-all-content .em-row.em-flex ul li {
  list-style: none;
}

.billingpopup .popupMainContainer-all-content .em-row.em-flex ul li:before {
  content: '\2713';
  margin-right: 10px;
  font-size: 15px;
  color: var(--themeColor);
  font-weight: 900;
}

.try-Now .css-2b097c-container {
  width: 100% !important;
}

.try-Now .css-2b097c-container .css-yk16xz-control {
  background: var(--themeColor);
}

.try-Now .css-2b097c-container .css-1wa3eu0-placeholder {
  color: #ffffff;
  font-size: 16px;
}

.billingpopup .popupMainContainer-all-content .em-row.em-flex h3 {
  font-size: 12px;
  margin-bottom: 5px !important;
}

/* BILLING PAGE CSS */

.billing-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 15px;
}

.billing-row .billing-row-Item {
  position: relative;
  float: left;
  border: 1px solid #e6e5f2cf;
  background: #ffffff;
  border-radius: 8px;
  padding: 1em;
  width: 33%;
}

.billing-row .billing-row-Item .plan-amount {
  font-family: 'redHatBold';
  color: #ef3636;
  font-size: 23px;
}

.billing-row .billing-row-Item .plan-title-header {
  font-family: 'redHatBold';
  font-size: 23px;
  width: 100%;
}
.billing-row .billing-row-Item .next-billing {
  padding: 10px 0;
  font-family: 'redHatBold';
  font-size: 12px;
}

.billing-row .billing-row-Item .coins_label {
  color: #90a3bf;
  font-size: 17px;
}

.billing-row .billing-row-Item .coins_left {
  font-size: 30px;
  font-family: 'redHatBold';
}

.billing-row .billing-row-Item .userlicenses-label {
  font-size: 20px;
  font-family: 'redHatBold';
}

.billing-row .billing-row-Item .border-only {
  border: none;
  border-right: 2px solid #90a3bf;
  height: 40px;
}

.billing-row .billing-row-Item .licenses_Icon {
  position: absolute;
  left: 0;
  font-size: 25px;
  top: 0;
}

.billings-receipt {
  margin: 20px 0 0 !important;
  border: 1px solid #e6e5f2cf;
  background: #ffffff;
  border-radius: 8px;
  padding: 10px !important;
}

.billings-receipt .o-p-L-ic-md {
  height: -webkit-fill-available !important;
  position: absolute;
}

.billings-receipt .heading-table {
  font-family: 'redHatBold';
  font-size: 22px;
}

.billings-receipt .payments {
  width: 100%;
  float: left;
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  position: relative;
}

.billings-receipt .payments .section-desc {
  width: 100%;
  float: left;
  position: relative;
}

.billings-receipt .payments .section-desc.scrollable-div-contents {
  height: calc(100vh - 460px) !important;
}

.billings-receipt .payments table {
  border-collapse: collapse;
  width: 100%;
  white-space: pre;
}

.billings-receipt .payments table th {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-family: 'redHatBold';
  padding: 10px;
  text-align: left;
}

.billings-receipt .payments table tr {
  border: 1px solid #ddd;
}

.billings-receipt .payments table td {
  font-size: 14px;
  font-family: 'redHatMedium';
  padding: 10px;
  text-align: left;
}

.overFlow-nextLine {
  overflow-wrap: break-word;
}

.billings-receipt .btn-link.lmrecords {
  gap: 5px;
  position: absolute;
  top: 25px;
  right: 20px;
  z-index: 9;
}

.em-coin-badge-icon-billing {
  width: 42px;
}

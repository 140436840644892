.em-cbread {
  position: relative;
  float: left;
  margin: 0;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.em-cbread .cb-title {
  font-size: 12px;
  color: gray;
  cursor: pointer;
  font-weight: bold;
}

.em-cbread .cb-title.cb-active {
  font-size: 12px;
  font-weight: bold;
  color: var(--themeColor);
  font-weight: bold;
}

.em-cbread .cb-arrow {
  font-size: 9px;
  color: grey;
  cursor: pointer;
}

.em-cbread .cb-title:hover {
  text-decoration: underline;
}

.em-cbread .cb-title.cb-active:hover {
  cursor: default !important;
  text-decoration: none !important;
}

.parent-Wloader{
    justify-content: center;
    padding: 20px 0;
    flex-direction: column !important;
    gap: 20px;
}
.waiting-Loader {
    width: 100px;
    height: 100px;
    border: 6px dotted var(--themeColor);
    border-style: solid solid dotted dotted;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: waitingRotation 2s linear infinite;
}

.waiting-Loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px dotted #000000;
    border-style: solid solid dotted;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    animation: waitingRotationBack 1s linear infinite;
    transform-origin: center center;
}

@keyframes waitingRotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes waitingRotationBack {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}
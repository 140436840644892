.left-portion {
  display: flex;
  width: 170px;
  background-color: #fafdfc;
  height: 100%;
}

.left-portion-collapse {
  display: flex;
  width: 50px;
  background-color: #fafdfc;
}

.body-content {
  display: flex;
  float: left;
  flex-direction: row;
  flex: 1px 1;
  width: 100%;
  height: calc(100vh - 55px);
}

.right-portion {
  display: flex;
  flex: 1px;
}
.right-portion-collapse {
  display: flex;
  flex: 1px;
}

@media (max-width: 700px) {
  .left-portion {
    display: flex;
    width: 50px;
    background-color: #fafbfe;
    height: auto;
  }

  .right-portion {
    display: flex;
    flex: 1px;
  }
  .right-portion-collapse {
    display: flex;
    flex: 1px;
  }
}

.progressPage .progress-bar {
  width: 70%;
  background-color: #ddd;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  background-color: transparent;
  font-family: monospace !important;
}

.progressPage .bar {
  background-color: lightgrey;
  width: 25%;
  height: 7px;
  margin-top: 12px;
  margin-right: -7px;
  margin-left: -7px;
}

.progressPage .outer {
  background-color: var(--themeColor);
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: -3px;
  z-index: 1;
  display: flex;
  align-self: center;
}

.progressPage .inner {
  background-color: var(--themeColor);
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
  display: flex;
  align-self: center;
  margin-left: 6px;
  opacity: 1;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
}

.progressPage .color {
  background-color: var(--themeColor);
  width: 25%;
  height: 7px;
  margin-top: 12px;
  margin-right: -7px;
  margin-left: -7px;
}

.progressPage .number {
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  margin-top: -5px;
}

.progressPage .numbers {
  font-weight: bold;
  font-size: 15px;
  color: var(--themeColor);
  margin-right: 10px;
}

.progressPage .status {
  font-weight: bold;
  font-size: 15px;
  color: var(--themeColor);
  margin-right: 10px;
}

.dataset-details {
  border: 1px solid red;
  padding: 10px;
  border-radius: 8px;
  margin-top: 20px;
}

.restore-description {
  color: '#2D3748';
  font-size: 13px;
  font-family: 'redHatRegular';
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
}

.restoreBox span {
  font-family: 'redHatRegular';
  font-weight: 600;
}

.orestore-component {
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
}

.orestore-component .orestore-title {
  font-size: 18px;
  font-family: 'redHatBold';
  padding: 20px 20px 0;
}

.orestore-component span.fewer-more {
  color: var(--themeColor);
  text-decoration: underline;
  font-size: 14px;
}

.orestore-component .op-title {
  font-size: 16px;
  margin-bottom: 20px;
  padding: 20px 20px 0;
  width: 100%;
  float: left;
}

.orestore-component .op-title p {
  width: 100%;
  float: left;
  overflow: hidden;
}

.oprogress-container {
  width: 97%;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin: 10px;
}

._main_sec {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0 0 0px 0;
  width: 26%;
  line-height: 1.2em;
  word-wrap: break-word;
  word-break: break-word;
  font-family: 'redHatMedium';
  color: #2a2e34;
  right: 10px;
  z-index: 99999;
}

._main_sec ._tops_sec {
  margin: 0;
  width: 100%;
  padding: 0px 0;
  background: #1d1d1d;
  float: left;
  border-radius: 3px 3px 0 0;
  align-items: center;
  background-color: #1d1d1d;
  border: 1px transparent solid;
  color: #fff;
  line-height: normal;
}
._toggleIcon {
  position: relative;
  right: 15px;
  float: right;
  margin: 10px 0 10px;
  cursor: pointer;
  color: #ffffff;
}
._main_data_col {
  position: relative;
  width: 100%;
  text-align: left;
  overflow-y: auto;
  height: auto;
  max-height: 50vh;
  border-top: groove;
  margin: 0px;
  padding: 10px 10px 10px;
  background: #ffffff;
  background-color: #fff;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

._main_data_col::-webkit-scrollbar {
  width: 10px;
  cursor: hand;
}

._main_data_col::-webkit-scrollbar-track {
  background: #ffffff; /* color of the tracking area */
}

._main_data_col::-webkit-scrollbar-thumb {
  background-color: #2a2d33; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

._main_data_col .item {
  position: relative;
  width: 100%;
  text-align: left;
  float: left;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._main_data_col .item .itemname {
  width: 55%;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 12px;
}

._main_data_col .item .itemLoader {
  width: 35%;
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: 800;
  position: relative;
}

._main_data_col .item .itemLoader span.information {
  position: absolute;
  top: -15px;
  right: 10px;
  cursor: pointer;
}
._main_data_col .item .itemLocation {
  width: 10%;
  margin: 0;
  padding: 0;
}
._main_data_col .item .itemLocation span {
  float: left;
  margin: 0;
  cursor: pointer;
  text-align: center;
  width: 100%;
}

._main_data_col .item .itemLoader .css-18lt272 {
  border-radius: 25px;
}

._main_data_col .item .itemLoader .css-e99m63,
.css-1iqxcui {
  background-color: #3f93ca;
}
._main_data_col .item .close-btn {
  position: absolute;
  right: 0;
  top: -15px;
  font-size: 12px;
  color: red;
  font-weight: 800;
  opacity: 1;
}
._main_data_col .item .close-btn :hover {
  transform: scale(1.5);
}

._main_data_col .item .itemLoader span.information :hover {
  transform: scale(1.5);
}
._main_data_col .parentItem .errorMessage {
  float: left;
  position: relative;
  width: 100%;
  padding: 0 15px 0 0;
  color: rgb(220, 53, 69);
  font-size: 12px;
  font-family: 'redHatMedium';
  font-weight: 600;
}

._main_data_col .parentItem .errorMessage span {
  position: absolute;
  right: 0;
  top: 0;
  color: #000000;
  cursor: pointer;
}

._main_data_col .parentItem .errorMessage span :hover {
  transform: scale(1.5);
}

._main_sec ._tops_sec p {
  position: relative;
  float: left;
  color: #ffffff;
  margin: 10px 0 10px 20px;
  font-weight: 800;
}

.appstatus-emly-btn-primary {
  background-color: black;
  border-radius: 6px;
  width: 170px;
  font-size: 9px;
  font-family: 'redHatBold';
  font-size: 100%;
  white-space: pre;
  color: #fff;
  text-align: center;
  border: none;
  height: 30px;
}

.appstatus-emly-btn-outline {
  color: var(--themeColor);
  background-color: #fff;
  border-radius: 6px;
  font-size: 9px;
  font-family: 'redHatBold';
  font-size: 100%;
  white-space: pre;
  /* min-width: 10em; */
  text-align: center;
  border: 1px solid var(--themeColor);
  margin-left: 10px;
  width: 120px;
  height: 30px;
}

.status-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.item-status {
  font-size: 12px;
  color: grey;
  margin-left: 5px;
  font-weight: bold;
}

.item-status-hr {
  font-size: 12px;
  color: grey;
  margin-left: 5px;
  font-weight: bold;
  border-left: 3px solid lightgray;
  padding-left: 6px;
}

.item-name {
  font-weight: 800;
  margin-top: 5px;
  margin-left: 10px;
  width: 285px;
}

.header-action-panel {
  width: 100%;
  float: right;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding: 0 0px;
  justify-content: end;
}

.mainDashboardConatiner .right-body-panel .admin-search-box-parent {
  display: flex;
  float: left;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 35px;
}

.mainDashboardConatiner .right-body-panel .admin-search-box-parent .top-body-search-box {
  position: relative;
  width: 45%;
}

.mainDashboardConatiner
  .right-body-panel
  .admin-search-box-parent
  .top-body-setting-help-icon
  .current-user-name
  #name {
  font-size: 12px;
  line-height: normal;
  font-family: 'redHatMedium';
  position: absolute;
  right: 0px;
  width: 200px;
  background: #5ccbb5;
  padding: 4px;
  border-radius: 5px;
  color: white;
}

.mainDashboardConatiner .right-body-panel .admin-search-box-parent .top-body-search-box #search-icon {
  left: 10px;
  position: absolute;
  top: 24%;
}

.mainDashboardConatiner .right-body-panel .admin-search-box-parent .top-body-setting-help-icon .current-user-name {
  color: #718096;
}

.mainDashboardConatiner .right-body-panel .admin-search-box-parent .top-body-search-box #search-box {
  border-radius: 51px;
  outline: none;
  border: 0.1px solid #e2e8f0;
  padding: 7px;
  min-width: 36%;
  width: 100%;
  border-top: 0 !important;
  padding-left: 41px !important;
}

.mainDashboardConatiner .right-body-panel .admin-search-box-parent .top-body-setting-help-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
}

.mainDashboardConatiner .right-body-panel .admin-search-box-parent .top-body-setting-help-icon .current-user-name {
  color: #718096;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.mainDashboardConatiner
  .right-body-panel
  .admin-search-box-parent
  .top-body-setting-help-icon
  .current-user-name
  #name {
  display: none;
}

.mainDashboardConatiner
  .right-body-panel
  .admin-search-box-parent
  .top-body-setting-help-icon
  .current-user-name
  :hover
  + #name {
  display: block;
}

.mainDashboardConatiner .right-body-panel .admin-search-box-parent .top-body-setting-help-icon .organizationDrop-down {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  grid-gap: 10px;
  text-transform: capitalize;
  color: #718096;
  justify-content: center;
}

.mainDashboardConatiner
  .right-body-panel
  .admin-search-box-parent
  .top-body-setting-help-icon
  .organizationDrop-down
  i {
  cursor: pointer;
}

.mainItemView {
  width: 195px;
  position: relative;
  z-index: 999;
}

.mainItemView .top-Item {
  border: 1px solid var(--themeColor);
  padding: 7px 13px;
  position: relative;
  border-radius: 7px;
  display: flex;
  color: #90a3bf;
  background: white;
  align-items: center;
  justify-content: space-between;
}

.mainItemView .top-Item i {
  color: var(--themeColor);
  margin-left: 5px;
  font-size: 15px;
}

.mainItemView .top-Item span {
  position: absolute;
  top: -9px;
  background: white;
  left: 10px;
  color: var(--themeColor);
  font-size: 10px;
}

.mainItemView .organizations {
  padding: 5px 10px;
  background: #ffffff;
  /* box-shadow: 0px 0.25px 30px rgb(45 55 72 / 10%);
  border-radius: 10px; */
}

.mainItemView .organizations .items {
  padding: 8px 0px 8px 6px;
  width: 100%;
  color: #2d3748;
  overflow: hidden;
}

.mainItemView .organizations .items .o-title {
  width: 88%;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
}

.mainItemView .organizations .items .o-tick {
  width: 10%;
}

.mainItemView .organizations .items:hover {
  background: #2d3748;
  color: white !important;
}

.mainItemView .organizations .items.checked {
  display: flex;
  justify-content: space-between;
  color: #2d3748 !important;
  gap: 1%;
}

.mainItemView .organizations .items.checked i {
  color: green;
  font-size: 12px;
}

.mainItemView .organizations .items.checked:hover {
  background: white;
  color: #2d3748;
  cursor: auto;
}

.admin-comp .json-to-table table {
  font-size: 13px;
  font-family: 'redHatRegular';
  font-weight: 800;
}

.admin-comp .json-to-table td {
  text-align: left !important;
}

.admin-comp .json-to-table td,
.json-to-table th {
  padding: 5px !important;
  border: 1px solid #bebebe !important;
}

.notifications-top {
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  position: absolute;
  background-color: #fff;
  width: 380px;
  z-index: 90;
  right: 50px;
  top: 35px;
  padding: 10px;
}

.topicons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  align-items: center;
  position: relative;
}

.topbarr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fafdfc;
  padding: 10px 10px 10px 14px;
}

.project-head {
  display: flex;
  align-self: flex-start;
}

.projectHTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: bold;
  color: var(--themeColor);
  cursor: pointer;
  line-height: 0.7;
  font-size: 20px;
}

.fa-tasks {
  font-weight: bold;
  color: var(--themeColor);
}

.project-head-row {
  display: flex;
  flex-direction: row;
  margin-left: 50px;
  align-items: center;
  gap: 14px;
  margin-top: 4px;
}

.profile-icons-div {
  display: flex;
  align-self: flex-end;
  width: auto;
}

.notification-activities {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  height: 300px;
}

.notification-messages {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  height: 300px;
}

.item {
  font-weight: 800;
  margin-top: 5px;
  margin-left: 10px;
}

.item-time {
  margin-left: 10px;
  font-size: 11px;
  color: grey;
}

.notification-item-div {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 15px;
}

.notification-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.notification-item-avatar {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  align-self: center;
}

.notification-status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 200px;
}

.higlighted {
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--themeColor);
  align-self: center;
}

.notification-go-toApps {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.main-topbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 700px) {
  .projectHTitle {
    font-weight: bold;
    color: var(--themeColor);
    cursor: pointer;
    line-height: 0.9;
    font-size: 15px;
  }

  .project-head-row {
    display: flex;
    flex-direction: row;
    margin-left: -40px;
    z-index: 1;
  }

  .profile-icons-div {
    display: flex;
    align-self: flex-end;
    width: 100px;
    margin-right: 10px;
  }
}

.em-coin-badge-icon {
  width: 20px;
}

.em-coin-badge-span {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 70px;
  white-space: nowrap;
}

.em-credit-badge {
  color: #000000;
  border: 1px solid #8080803d;
  padding: 2px 10px;
  border-radius: 34px;
  font-size: 10px;
  gap: 6px;
  align-items: center;
  align-items: flex-end;
  font-family: 'redHatBold';
  cursor: pointer;
}

.em-coin-spinner-icon.fa-pulse {
  font-size: 18px;
}

.em-credit-badge.cbGreen {
  border: 1px solid #8080803d;
}

.em-credit-badge.cbRed {
  background: #ff0000;
  border: none;
  color: #ffffff;
}

.em-credit-badge.cborange {
  background: #ffa500;
  border: none;
  color: #ffffff;
}

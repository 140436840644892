/* style to custom cards  */
.mainDashboardConatiner .right-body-panel .cards-parent-div {
  display: flex;
  width: 100%;
  float: left;
  margin: 30px auto 10px;
}
.mainDashboardConatiner .right-body-panel .cards-parent-div .card {
  width: 240px;
  background-color: #ffff;
  margin-right: 18px;
  border-radius: 20px;
  border: 0.1em solid rgba(206, 205, 205, 0.3);
  box-shadow: 0px 1px 9px -2px var(--boxShadow);
  cursor: pointer;
  padding: 14px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.mainDashboardConatiner .right-body-panel .cards-parent-div .card .back-image {
  /* background: aqua; */
  /* min-height: 150px; */
  min-height: 183px;
}

.mainDashboardConatiner .right-body-panel .cards-parent-div .card .back-image img {
  width: 100%;
}

.mainDashboardConatiner .right-body-panel .cards-parent-div .card .heading {
  color: #2d3748 !important;
  font-weight: bold;
  line-height: 18px;
  font-family: system-ui;
  font-size: 13px;
  letter-spacing: 0.12px;
  padding-top: 9px;
}
.mainDashboardConatiner .right-body-panel .cards-parent-div .card .description {
  color: #a0aec0;
  font-weight: bold;
  line-height: 15px;
  font-family: system-ui;
  font-size: 10px;
}

/* 
adding new style for modal on switching space or org 
This Css is used for confirmation modal inside modal  
in Cayley 2 form stacking
*/
.mainDashboardConatiner .right-body-panel .modalConatiner {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 10%;
  background-color: rgba(0, 0, 0, 0.7);
}

.mainDashboardConatiner .right-body-panel .modalConatiner-content {
  background-color: #fefefe;
  margin: 0% auto;
  padding: 0px 0px 20px;
  text-align: center;
  border: 1px solid #888;
  width: 30%;
}

.mainDashboardConatiner .right-body-panel .modalConatiner-footer {
  text-align: center;
  margin-top: 20px;
}
.mainDashboardConatiner .right-body-panel .modalConatiner-footer :first-child {
  margin-right: 10px;
}

.mainDashboardConatiner .right-body-panel .modalConatiner .switch-modal {
  padding: 2%;
  border-radius: 14px;
  width: 40%;
  box-shadow: 0px 1px 9px -2px var(--boxShadow);
}
.mainDashboardConatiner .right-body-panel .modalConatiner .switch-modal .parent-switch {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.mainDashboardConatiner .right-body-panel .modalConatiner .switch-modal .parent-switch .heading-part {
  font-family: system-ui;
  font-size: 17px;
  font-weight: 500;
  line-height: 100%;
}
.mainDashboardConatiner .right-body-panel .modalConatiner .switch-modal .parent-switch .button-part {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 22px;
  padding-bottom: 4px;
}
.mainDashboardConatiner .right-body-panel .modalConatiner .switch-modal .parent-switch .button-part .button-yes {
  background: var(--themeColor);
  border: none;
  outline: none;
  padding: 6px;
  border-radius: 8px;
  text-align: center;
  justify-content: center;
  width: 36%;
  color: #ffffff;
  font-family: system-ui;
  font-weight: 700;
  line-height: 21px;
  font-size: 14px;
}
.mainDashboardConatiner .right-body-panel .modalConatiner .switch-modal .parent-switch .button-part .button-no {
  background: #ffffff;
  outline: none;
  padding: 6px;
  border-radius: 8px;
  text-align: center;
  justify-content: center;
  width: 36%;
  color: #2d3748;
  font-family: system-ui;
  font-weight: 700;
  line-height: 21px;
  font-size: 14px;
  border: 1px solid darkturquoise;
}

.mainDashboardConatiner .LeftSidePanel .modalConatiner {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 10%;
  background-color: rgba(0, 0, 0, 0.7);
}

.mainDashboardConatiner .LeftSidePanel .modalConatiner-content {
  background-color: #fefefe;
  margin: 0% auto;
  padding: 0px 0px 20px;
  text-align: center;
  border: 1px solid #888;
  width: 30%;
}

.mainDashboardConatiner .LeftSidePanel .modalConatiner-footer {
  text-align: center;
  margin-top: 20px;
}
.mainDashboardConatiner .LeftSidePanel .modalConatiner-footer :first-child {
  margin-right: 10px;
}

.mainDashboardConatiner .LeftSidePanel .modalConatiner .switch-modal {
  padding: 2%;
  border-radius: 14px;
  width: 40%;
  box-shadow: 0px 1px 9px -2px var(--boxShadow);
}
.mainDashboardConatiner .LeftSidePanel .modalConatiner .switch-modal .parent-switch {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.mainDashboardConatiner .LeftSidePanel .modalConatiner .switch-modal .parent-switch .heading-part {
  font-family: system-ui;
  font-size: 17px;
  font-weight: 500;
  line-height: 100%;
}
.mainDashboardConatiner .LeftSidePanel .modalConatiner .switch-modal .parent-switch .button-part {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 22px;
  padding-bottom: 4px;
}
.mainDashboardConatiner .LeftSidePanel .modalConatiner .switch-modal .parent-switch .button-part .button-yes {
  background: var(--themeColor);
  border: none;
  outline: none;
  padding: 6px;
  border-radius: 8px;

  width: 36%;
  color: #ffffff;
  font-family: system-ui;
  font-weight: 700;
  line-height: 21px;
  font-size: 14px;
}
.mainDashboardConatiner .LeftSidePanel .modalConatiner .switch-modal .parent-switch .button-part .button-no {
  background: #ffffff;
  outline: none;
  padding: 6px;
  border-radius: 8px;

  width: 36%;
  color: #2d3748;
  font-family: system-ui;
  font-weight: 700;
  line-height: 21px;
  font-size: 14px;
  border: 1px solid darkturquoise;
}
/* style scroll bars */
.mainDashboardConatiner div.cards-parent-div::-webkit-scrollbar,
.mainDashboardConatiner div.right-body-panel::-webkit-scrollbar {
  height: 5px;
  width: 10px;
}

/* Track */
.mainDashboardConatiner div.cards-parent-div::-webkit-scrollbar-track,
.mainDashboardConatiner div.right-body-panel::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
.mainDashboardConatiner div.cards-parent-div::-webkit-scrollbar-thumb,
.mainDashboardConatiner div.right-body-panel::-webkit-scrollbar-thumb {
  background: rgb(197, 191, 191);
  border-radius: 20px;
}

/* Handle on hover */
.mainDashboardConatiner div.cards-parent-div::-webkit-scrollbar-thumb:hover,
.mainDashboardConatiner div.right-body-panel::-webkit-scrollbar-thumb:hover {
  background: rgb(197, 191, 191);
}

.mainDashboardConatiner .right-body-panel #loadingDiv {
  position: relative;
  z-index: 10000;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  color: #fff;
  overflow: auto;
  background: #00000070;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.mainDashboardConatiner .right-body-panel #plainBg {
  position: relative;
  z-index: 10000;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.mainDashboardConatiner .right-body-panel #centerLoadingIconDiv {
  position: fixed;
  z-index: 10000;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  color: #fff;
  overflow: auto;
  background: #00000070;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.mainDashboardConatiner .right-body-panel #centerLoadingIconDiv .centerLoadingIcon {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

#root #centerLoadingIconDiv {
  position: fixed;
  z-index: 10000;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  color: #000000;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

#root #centerLoadingIconDiv .centerLoadingIcon {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/*Media Query Section Starts here*/
@media only screen and (min-width: 1681px) {
}

@media only screen and (max-width: 1329px) {
}

@media only screen and (max-width: 1255px) {
}

@media only screen and (min-device-width: 480px) and (max-device-width: 767px) and (orientation: landscape) {
}

@media only screen and (max-width: 1023px) {
}

@media only screen and (min-width: 812px) and (max-width: 823px) {
}

@media only screen and (max-width: 599px) {
}

@media only screen and (min-width: 450px) and (max-width: 450px) {
}

@media only screen and (max-width: 479px) {
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 367px) {
}

@media only screen and (max-width: 359px) {
}

@media only screen and (max-width: 290px) {
}
/*Media Query Section Ends here*/

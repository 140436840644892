.Welcome {
  position: relative;
  width: 100%;
  float: left;
  clear: both;
}

.Welcome .project-button {
  color: white;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  width: 30%;
  height: 45px;
  background-color: var(--themeColor);
  font-family: 'redHatMedium' !important;
}

.Welcome .project-button-continue-disabled {
  color: white;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  width: 30%;
  height: 45px;
  background-color: lightgrey;
  border-color: var(--themeColor);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  pointer-events: none;
}

.Welcome .tab-div {
  height: auto !important;
}

.Welcome .tab-div .tab-active,
.Welcome .tab {
  height: auto !important;
  flex-direction: column;
}

.Welcome .tab-div .tab-ico {
  width: 30px;
}

.select-container {
  width: 200px;
  /* Set the width as needed */
}

.selected-industry {
  position: absolute;
  /* top: -25px; 
  left: 10px; */
  background-color: #fff;
  /* Set the background color */
  padding: 0 5px;
  /* Add padding for better styling */
  margin-left: 5px;
  color: var(--themeColor);
}

.custom-select {
  width: 100%;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  background-color: #fff;
  color: #000;
}

/* Optional: Style for when the select is focused */
.custom-select:focus {
  border-color: lightgrey;
  /* Change the color as needed */
}

.overlay {
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: row;
  flex: 1px;
  justify-content: center;
}

.overlay .transparent {
  background-color: var(--themeColor);
  opacity: 0.4;
  flex: 1px;
}

.overlay .modalConatiner-all-content {
  position: relative;
  margin: 0% auto;
  padding: 60px;
  width: 100%;
  height: 100%;
  display: 'flex';
  justify-content: 'center';
  height: '100vh';
  align-items: 'center';
  flex: 1px;
}

.projectcreation {
  color: var(--themeColor);
  font-weight: bold;
}

.custom-option {
  position: relative;

  padding-bottom: 5px;
  padding-left: 10px;
  border-radius: 5px;
  margin-top: -22px !important;
}

.custom-option:hover {
  background-color: #5ccbb5;
  color: #fff;
}

.submit-buttonn {
  background-color: #5ccbb5;
  display: block;
  align-self: center;
  border: none;
  width: 80%;
  color: #fff;
  font-size: 16px;
  height: 60px;
  border-radius: 5px;
}

.Welcome .continue-button {
  color: white;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  width: 17%;
  height: 38px;
  background-color: #2d3748;
  border-color: #2d3748;
  margin-left: 20px;
}

.Welcome .cancel-button {
  color: grey;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  width: 30%;
  height: 35px;
  background-color: #fff;
  border-color: lightgrey;
}

.Welcome .selected-picker {
  border-width: 1;
  outline: 'none';
  color: var(--themeColor);
  font-weight: 700;
  font-size: 14;
  border-radius: 5;
  border-color: var(--themeColor);
  width: 100%;
  height: 65px;
  padding: 5;
  text-align: 'center';
  background-color: white;
  position: relative;
}

.project-creation-panel-open {
  background-color: var(--themeColor);
  padding: 10px;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  border-radius: 20px;
  margin-bottom: -200px;
  position: absolute;
  margin-top: 5px;
}

.Welcome .picker-panel1 {
  border-width: 1;
  outline: 'none';
  color: var(--themeColor);
  font-weight: 700;
  font-size: 14;
  border-radius: 5;
  border-color: var(--themeColor);
  width: 100%;
  /* height: 65px; */
  padding: 5;
  /* text-align: 'center'; */
  margin-top: 20px;
  font-family: 'redHatMedium' !important;
}

.Welcome .picker-panel-z {
  z-index: 3;
  border-width: 1;
  outline: 'none';
  color: var(--themeColor);
  font-weight: 700;
  font-size: 14;
  border-radius: 5;
  border-color: var(--themeColor);
  width: 100%;
  /* height: 65px; */
  padding: 5;
  /* text-align: 'center'; */
  margin-top: 20px;
  font-family: 'redHatMedium' !important;
}

.Welcome .neww {
  margin-top: 10px;
}

.Welcome .picker-panel {
  outline: none !important;
  border-width: 1;
  outline: 'none';
  color: var(--themeColor);
  font-weight: 700;
  font-size: 14;
  border-radius: 5;
  border-color: var(--themeColor);
  width: 100%;
  /* height: 65px; */
  padding: 5;
  /* text-align: 'center'; */
  margin-top: 20px;
  font-family: 'redHatMedium' !important;
}

.flow-title {
  color: #fff;
  font-weight: bold;
  text-align: start !important;
  font-size: 15px;
}

.Welcome .flow-project {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 13px;
  outline: none !important;
  background-color: rgba(45, 55, 72, 0.02);
  color: red;
  font-weight: bold;
}

.Welcome input[type='text'] {
  width: 100%;
  padding: 10px;
  margin: 0px 0 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none !important;
  font-size: 16px;
}

/* Description textarea style */
.Welcome textarea {
  width: 100%;
  padding: 10px;
  margin: 0px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none !important;
  font-size: 16px;
}

.Welcome .css-1wa3eu0-placeholder {
  color: '#fff';
}

.Welcome .css-1wa3g1l-icon {
  color: red;
  /* Change the color to the desired color */
}

.Welcome .custom-select-label1 {
  position: absolute;
  margin-top: 14px;
  /* Adjust this value as needed */
  left: 0;
  background-color: var(--themeColor) !important;
  /* Match your background color */
  padding: 0 5px;
  font-weight: bold;
  z-index: 1;
  margin-left: 20px;
  color: #fff;
  font-size: 12px;
}

.Welcome .custom-select-label {
  position: absolute;
  margin-top: 30px;
  /* Adjust this value as needed */
  left: 0;
  background-color: var(--themeColor) !important;
  /* Match your background color */
  padding: 0 5px;
  font-weight: bold;
  z-index: 10;
  margin-left: 20px;
  color: #fff;
  font-size: 12px;
}

.Welcome .custom-select-label-div {
  z-index: 4;
}

.Welcome .css-1uccc91-singleValue {
  color: #fff !important;
}

.Welcome .css-tlfecz-indicatorContainer {
  color: #fff !important;
}

.Welcome .css-lgtu0rj-indicatorContainer {
  color: #fff !important;
}

.Welcome textarea {
  resize: none;
}

.Welcome label {
  color: #000;
  font-weight: normal !important;
  margin-bottom: 0px !important;
}

.picker-panel-z .css-1pahdxg-control input {
  color: #fff !important;
  /* Change the text color to your desired color */
}

.picker-panel .css-1pahdxg-control input {
  color: #fff !important;
  /* Change the text color to your desired color */
}

.sample-projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: calc(100vh - 245px);
  overflow-x: hidden;
  padding-bottom: 1%;
}

.em-row.locenter {
  width: 100%;
  position: relative;
  height: calc(100vh - 290px);
}

.sample-projects.scrollHeight-projects {
  max-height: calc(100vh - 355px);
}

.flows-projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
  overflow-x: hidden;
  max-height: calc(100vh - 250px);
}

.project-group-help {
  font-family: 'redHatBold';
  right: 0;
  float: right;
  padding-top: 5px;
}

.outer-scroll {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  max-height: calc(100vh - 150px);
}

.welcome-user {
  gap: 10px;
  margin-top: 15px;
  align-content: space-between;
  justify-content: space-between;
  align-items: start;
}

.welcome-user .sec1 {
  position: relative;
  width: 50%;
  overflow: hidden;
}

.welcome-user .sec2 {
  position: relative;
  width: 40%;
  overflow: hidden;
}

.welcome-user .W-header {
  color: #2d3748;
  margin: 60px 0;
  font-family: 'redHatMedium';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.welcome-user .W-header span {
  color: #2d3748;
  font-family: 'redHatMedium';
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.welcome-user h3 {
  color: var(--themeColor);
  font-family: 'redHatMedium';
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 33px !important;
}

.welcome-user h4 {
  font-family: 'redHatMedium';
  margin: 30px 0;
  color: #95989d;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 33px !important;
}

.toggle-container {
  display: flex;
  align-items: end;
  border: 1px solid #ccc;
  /* Grey border */
  border-radius: 10px;
  width: 160px;
  height: 33px;
  margin-top: 10px;
  margin-right: 10px;
  padding-bottom: 4px;
}

.text-before-switch,
.text-after-switch {
  margin-right: 10px;
  /* Adjust spacing as needed */
  font-size: 12px;
  margin-left: 10px;
  /* padding-top: 10px; */
}

.toggle-switch {
  display: inline-block;
  cursor: pointer;
}

.project-flow.main-container {
  position: absolute;
  right: 0;
  top: -5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.create-pr-headerline {
  color: #8b9eb9;
  font-weight: 600;
  margin: 20px 0 0 0 !important;
}

.em-flex.gback svg {
  width: 20px;
  height: 20px;
  fill: #ffffff;
}

.text_input-hint {
  font-size: 13px;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 2px;
  font-family: 'redHatMedium' !important;
}

.user-flow-title {
  font-weight: '600';
  margin-left: 30;
  margin-bottom: 30;
  margin-top: 30;
  color: var(--themeColor);
}

.em-project-group {
  padding-bottom: 10px;
}

.card-tick-Icon {
  position: absolute;
  width: 24px;
  right: 5px;
  top: 5px;
}

.pr-warning-parent {
  width: 100%;
  position: relative;
  border-radius: 11px;
  float: left;
  margin: 10px 0;
}

.em-flex.pr-warning {
  flex-flow: nowrap;
  justify-content: flex-start;
}

.em-flex.pr-warning .fl-imag {
  width: 270px;
}

.em-flex.pr-warning .fl-imag img {
  width: 100%;
  padding: 10px;
}

.em-flex.pr-warning .fl-txt {
  font-size: 20px;
  font-family: 'redHatRegular';
}

.em-flex.pr-warning .fl-txt b {
  font-family: 'redHatBold' !important;
}

.ufont {
  font-size: 18px;
}

.em-flex.pr-warning .fl-txt span {
  font-family: 'redHatBold';
}

.ul-pc-group {
  list-style: none;
  margin: 0;
  padding: 10px 0;
}

.ul-pc-group.em-oneScroll {
  max-height: calc(100vh - 260px);
}

h2.ul-pcgroup {
  font-size: 13px;
  font-family: 'redHatMedium';
}

.ul-pc-group li {
  padding: 5px 0;
  display: flex;
  gap: 15px;
  place-content: space-between;
}

/* .ul-pc-group li:first-child {
  font-family: 'redHatBold';
  font-size: 13px;
} */

.ul-pc-group li .img-Icon .already-added {
  color: var(--themeColor);
  font-family: 'redHatBold';
}

.ul-pc-group li .img-Icon {
  display: -webkit-inline-box;
  gap: 10px;
}

.cp-invite-title {
  font-size: 17px;
  font-family: 'redHatMedium';
  margin: 0;
}

.pr-switch-avail {
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 0px;
  width: 160px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  margin-top: 5px;
  padding: 4px;
}

.pr-switch-avail.build {
  right: 208px;
}

.pr-switch-avail .flp-sw {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.pr-switch-avail .flp-sw .react-switch-bg {
  height: 21px !important;
  width: 50px !important;
}

.pr-switch-avail .flp-sw .react-switch-bg div {
  height: 22px !important;
  width: 24px !important;
}

.pr-switch-avail .flp-sw .react-switch-handle {
  height: 18px !important;
  width: 18px !important;
}

.pr-switch-avail .flp-sw span {
  font-family: 'redHatMedium';
  font-size: 13px;
  position: absolute;
  background: #ffffff;
  top: -10px;
  color: var(--themeColor);
  left: 5px;
  pointer-events: none;
}

.buyNow_purchase .popupMainContainer-all-content {
  padding: 0px !important;
  border: none !important;
}
.popupMainContainer.buyNow_purchase {
  background-color: rgb(0 0 0 / 35%) !important;
}

.em-buyCourse-parent{
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  display: flex;
  justify-self: center;
}

.em-buyCourse-parent .em-buycourse-img {
  float: left;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #363636;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.em-buyCourse-parent .em-buycourse-img img{
  max-width: 90px;
}

.em-buyCourse-parent .em-buycourse-description{
  position: relative;
  float: left;
  width: 70%;
  margin: 0;
  padding: 15px;
}

.em-buyCourse-parent .em-buycourse-description b{
  font-family: 'redHatBold';
  font-size: 15px;
}

.em-buyCourse-parent .em-buycourse-description span{
  position: relative;
  float: left;
  width: 100%;
  margin: 0;
  font-family: 'redHatMedium';
  font-size: 13px;  
}

.em-buyCourse-parent .em-buycourse-description span.des_2{
  margin-top: 5px;
}

.em-buyCourse-parent .em-buycourse-description span.des_3{
  margin-top: 15px;
  display: flex;  
  gap: 12px;
  justify-content: flex-end;
}

.em-buyCourse-parent .em-buycourse-description span.des_4{
  margin-top: 15px;
  display: flex;  
  color: red;    
  font-size: 10px;
}

.em-buycourse-description ul{
  display: flex;
  padding: 0;
  list-style: none;
  margin: 0;
}

.em-buycourse-description ul li{
  width: 50%;
}

.em-buycourse-description span button.em-btn.later{
  background: white;
  color: black;
  border: 1px solid darkgray;
  padding: 2px 15px;
}

.em-buycourse-description span button.em-btn.-p-t-buy{
  border: 1px solid var(--themeColor);
  padding: 2px 15px;
}
.message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.message-content {
  background-color: #f1c40f;
  padding: 10px;
}

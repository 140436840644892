.tab-div {
  display: flex;
  flex-direction: row;
  height: 40px;
  box-shadow: inset 0 -1px 0 0 rgb(223, 229, 235);
  gap: 24px;
  border-bottom: 1px solid lightgrey;
}

.tab-div-text {
  display: flex;
  flex-direction: row;
  height: 40px;
}

.tab-active {
  height: 35px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 5px;
  cursor: pointer;
  margin-top: 5px;
  font-family: 'redHatBold';
  font-size: 14px;
  cursor: default;
  outline: none;
  -webkit-box-shadow: inset 0 -2px 0 0 var(--themeColor);
  box-shadow: inset 0 -2px 0 0 var(--themeColor);
  -webkit-box-shadow: inset 0 -2px 0 0 var(--themeColor);
  box-shadow: inset 0 -2px 0 0 var(--themeColor);
  font-weight: bold;
  border-bottom: 2px solid var(--themeColor);
  /* flex: 1px; */
}

.tab-active.isPointer {
  cursor: pointer !important;
}

.number-tab {
  width: 11px;
  height: 11px;
  border-radius: 5.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
}

.tab {
  margin-top: 5px;
  height: 35px;
  align-items: center;
  display: flex;
  font-size: 14px;
  color: gray;
  cursor: pointer;
  justify-content: center;
  /* flex: 1px; */
}

.tab-active-text {
  height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: var(--themeColor);
  border-top-left-radius: 18px 10px;
  border-top-right-radius: 18px 10px;
  margin-right: 5px;
  margin-top: 5px;
  border-bottom: 3px solid var(--themeColor);
}

.tab-text {
  margin-top: 5px;
  justify-content: center;
  height: 35px;
  align-items: center;
  display: flex;
  color: 'grey';
  justify-content: center;
  border-top-left-radius: 18px 10px;
  border-top-right-radius: 18px 10px;
  margin-right: 4px;
  border-bottom: 1px solid lightgrey;
  cursor: pointer;
}

.tab:hover,
.tab:active,
.tab:focus {
  color: var(--themeColor);
  cursor: pointer;
  outline: none;
  -webkit-box-shadow: inset 0 -2px 0 0 var(--themeColor);
  box-shadow: inset 0 -2px 0 0 var(--themeColor);
  -webkit-box-shadow: inset 0 -2px 0 0 var(--themeColor);
  box-shadow: inset 0 -2px 0 0 var(--themeColor);
}

.outer-div-tab {
  display: flex;
  flex-direction: column;
}

.tab-kabab {
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  position: fixed;
  background-color: #fff;
  width: 300px;
  float: right;
  z-index: 90;
  /* margin-left: -130px; */
  margin-top: 50px;
  padding: 11px;
  margin-left: -140px;
  font-family: 'redHatMedium' !important;
}

.kabab-container-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.kabab-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid lightgrey; */
  /* border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
  border-right-width: 0px; */
  width: auto;
  margin: 0 10px 0 0;
  font-family: 'redHatMedium' !important;
  gap: 10px;
  align-items: baseline;
}

.kabab-container-button {
  background: none !important;
  outline: none !important;
  border: none !important;
  padding: 0;
  color: grey;
}

.tab-kabab-div {
  display: flex;
  flex-direction: column;
}

.tab-kabab-div-item {
  padding: 7px;
  cursor: pointer;
  color: grey;
  margin-left: 20px;
}

.tab-kabab-div-item-highlight {
  padding: 7px;
  cursor: pointer;
  color: var(--themeColor);
  margin-left: 20px;
}

.kabab-container-leftChild {
  display: block;
  width: 100%;
}

.tab-key {
  background-color: #fafafa;
  padding: 2px;
  border-radius: 4px;
}

.tab-key-text {
  margin: 5px;
}

.kabab-action {
  border-radius: 20px;
  background: #27a2db;
  color: #fff;
  height: 30px;
  cursor: pointer;
}

.reports-name {
  font-size: 15px;
}

.reports {
  flex-direction: row;
  display: flex;
}

.tab-space {
  justify-content: space-between;
  display: flex;
  font-size: 14px;
  padding-left: 20px;
}

.Goback {
  padding: 1px;
  border-radius: 4px;
  border: 2px solid var(--themeColor);
  color: #000 !important;
  justify-content: space-between;
  display: flex;
  margin-bottom: 2px;
  cursor: pointer;
}

.Goback-disabled {
  padding: 2px;
  border-radius: 4px;
  border: 2px solid lightgrey;
  color: lightgrey !important;
  justify-content: space-between;
  display: flex;
  margin-bottom: 4px;
}

.Goback-text {
  margin: 5px;
  font-weight: 500;
  font-size: 13px;
}

.tab-righticon {
  font-size: 24px;
  padding-right: 10px;
  padding-top: 5px;
}

.advanced-mode {
  padding-top: 4px;
}

.menu-text {
  font-size: 10px;
  color: #93a6c1 !important;
}

.menu-text-highlight {
  font-size: 10px;
  color: var(--themeColor);
}

.horizontaline-kabab {
  display: flex;
  width: 2px;
  height: 46px;
  background-color: lightgrey;
  margin-top: 4px;
}

 .billingExpire .popupMainContainer-all-content {
    padding: 0px !important;
    border: none !important;
  }

  .popupMainContainer.billingExpire {
    background-color: rgb(0 0 0 / 35%) !important;
  }
  
  .billingExpire-parent{
    position: relative;
    width: 100%;
    float: left;
    clear: both;
    display: flex;
    justify-self: center;
  }
  
  .billingExpire-parent .billingExpire-img {
    float: left;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #363636;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .billingExpire-parent .billingExpire-img img{
    max-width: 90px;
  }
  
  .billingExpire-parent .billingExpire-description{
    position: relative;
    float: left;
    width: 70%;
    margin: 0;
    padding: 15px;
  }
  
  .billingExpire-parent .billingExpire-description b{
    font-family: 'redHatBold';
    font-size: 25px;
    overflow: hidden;
  }
  
  .billingExpire-parent .billingExpire-description span{
    position: relative;
    float: left;
    width: 100%;
    margin: 0;
    font-family: 'redHatMedium';
    font-size: 15px;  
  }

  .billingExpire-parent .billingExpire-description span.des_2{
    margin-top: 5px;
  }
  

  .billingExpire-parent .billingExpire-description span.des_note{
    margin-top: 10px;
    display: flex;  
    gap: 12px;
    justify-content: flex-start;
    font-size: 11px;
    font-family: 'redHatBold';
    color: #000000;
  }

  .billingExpire-parent .billingExpire-description span.des_3{
    margin-top: 15px;
    display: flex;  
    gap: 12px;
    justify-content: space-between;
    align-items: flex-end;
  }

  .billingExpire-parent .billingExpire-description span.des_3 a{
    font-size: 12px;
  }
  
  .billingExpire-parent .billingExpire-description span.des_4{
    margin-top: 5px;
    display: flex;  
    color: red;    
    font-size: 12px;
    justify-content: center;
  }

  .billingExpire-parent .billingExpire-description .css-2b097c-container {
    width: 100% !important;
  }
  
  .billingExpire-parent .billingExpire-description .css-2b097c-container .css-yk16xz-control {
    background: var(--themeColor);
  }
  
  .billingExpire-parent .billingExpire-description .css-2b097c-container .css-1wa3eu0-placeholder {
    color: #ffffff;
    font-size: 16px;
  }

.checkUberDeployment .first-para {
  width: 100%;
  float: left;
  margin: 5px auto;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.12px;
  color: #2d3748;
  align-self: center;
  font-family: redHatBold;
}

.checkUberDeployment .second-para {
  width: 100%;
  float: left;
  margin: 5px auto;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.12px;
  color: #2d3748;
  align-self: center;
  font-family: redHatBold;
}

.checkUberDeployment .action-btns {
  width: 100%;
  float: left;
  margin: 5px auto;
  text-align: center;
  font-family: redHatBold;
}

.checkUberDeployment .button {
  margin: 0px auto;
  text-align: center;
  color: #fff;
  background-color: #00659c;
  border-color: #005483;
  border: none;
  padding: 5px 25px;
  border-radius: 6px;
  display: inline-block;
}

.checkUberDeployment .centreLoadingSpinner {
  position: fixed;
  z-index: 10000;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  color: #fff;
  overflow: auto;
  background: #00000070;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.mainDashboardConatiner .right-body-panel .scrollable-div-contents.uber-service {
  height: calc(100vh - 115px);
}

.main-progress-div {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.progress-bar-uber {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  justify-content: center;
}

.progress-bar-uber-outer {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}

.progress-item-uber {
  display: flex;
  flex-direction: row;
}

.progress-item-uber {
  display: flex;
  flex-direction: row;
}

.line-uber {
  width: 100px;
  height: 4px;
  background-color: #f0f2f5;
  margin-top: 7px;
}

.circle-uber {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: var(--themeColor);
  opacity: 0.3;
}

.line-uber-highlight {
  width: 100px;
  height: 4px;
  background-color: var(--themeColor);
  margin-top: 7px;
}

.circle-uber-highlight {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: var(--themeColor);
}

.progressdiv {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}

.progressItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.status-item {
  font-size: 12px;
}

.status-index {
  color: var(--themeColor);
  margin-right: 15px;
  font-weight: bold;
}

.uber-status-name {
  display: flex;
  justify-content: flex-end;
  margin-right: 7px;
  margin-top: 10px;
}

.dataset-details {
  border: 1px solid red;
  padding: 10px;
  border-radius: 8px;
  margin-top: 20px;
}

.org-name {
  color: var(--themeColor);
}

.uber-navigator {
  position: absolute;
  right: 10px;
  z-index: 9;
  bottom: 10px;
  width: 350px;
  cursor: pointer;
}

.uber-navigator .rsc-title {
  font-size: 16px !important;
}

.uber-navigator .rsc-load {
  width: 15px !important;
  height: 15px !important;
}

.uber-navigator .rsc-image svg {
  width: 22px !important;
  height: 22px !important;
}

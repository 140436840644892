.card {
  position: relative;
  border: 1px solid #e1e1e1;
  border-radius: 11px;
  overflow: hidden;
  font-family: 'redHatMedium' !important;
  width: 30%;
  margin-top: 2%;
  margin-right: 2%;
  padding-bottom: 1%;
}

.group-card-outer {
  position: relative;
  padding: 0 !important;
  overflow: visible !important;
}

.group-card-inner {
  position: relative;
  width: 100% !important;
  margin: 0;
  padding: 0;
  height: 100%;
  top: 5px;
  right: -5px;
  overflow: visible !important;
  background: #ffffff;
}

.group-card-inner .card {
  width: 100% !important;
  margin: 0;
  padding: 0;
  height: 100%;
  top: 5px;
  right: -5px;
  background: #ffffff;
  padding-bottom: 3%;
}

.group-card-outer:hover,
.group-card-inner:hover {
  border: 2px solid var(--themeColor);
  cursor: pointer;
}

.card-no-border {
  border-radius: 11px;
  overflow: hidden;
  font-family: 'redHatMedium' !important;
  width: 100% !important;
}

.card-long {
  width: 660px;
  height: 97px;
  border: 1px solid #e1e1e1;
  border-radius: 11px;
  overflow: hidden;
  margin-bottom: 10px;
  font-family: 'redHatMedium' !important;
}

.card:hover,
.card-blank:hover {
  border: 2px solid var(--themeColor);
  cursor: pointer;
}

.circle {
  width: 50px;
  height: 50px;
  background-color: lightgrey !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.card-blank {
  margin-top: 2%;
  width: 30%;
  margin-right: 2%;
  border: 1px solid #e1e1e1;
  border-radius: 11px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'redHatMedium' !important;
  min-height: 200px;
}

.card-title {
  /* padding: 5px; */
  padding-top: 10px;
  /* text-align: center; */
  font-family: 'redHatMedium' !important;
  color: #0d062d;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 10px;
  padding-left: 6px;
}

.card-title-flow {
  font-weight: normal;
  font-family: 'redHatMedium' !important;
  font-size: 20px;
  line-height: normal;
  margin: 0;
}

.card-title-blank {
  font-family: 'redHatMedium' !important;
  color: #0d062d;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 10px;
  padding-left: 6px;
}

.card-content-flow {
  color: #6c7481;
  font-family: 'redHatMedium' !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  margin-left: 61px;
  max-height: 95px;
  overflow-y: hidden;
}

.card-content-div-no-border {
  display: flex;
  flex-direction: row;
  overflow: auto;
  max-height: 29vh;
  min-height: auto;
  padding-right: 10px;
}

.card-content-div {
  display: flex;
  flex-direction: row;
  max-height: 50px;
  min-height: 33px;
  overflow-y: hidden;
  overflow: hidden;
  margin-right: 18px;
}

.card-more {
  display: flex;
  position: fixed;
}

.card-content {
  /* padding: 10px; */
  color: #6c7481;
  font-family: 'redHatMedium' !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 18px;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  /* Set a fixed width for the div */
}
.card-image-no-border {
  /* text-align: center; */
  margin: 16px;
  /* width: 20%; */
  width: 100%;
}
.card-image {
  /* text-align: center; */
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-image-flow {
  /* text-align: center; */
  /* display: flex;
  align-self: flex-start; */
}

.card-image img {
  width: 100%;
  height: auto;
}

.card.sp-project {
  width: 24%;
  max-height: 200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}

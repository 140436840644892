.datoin-signup {
  width: 100%;
  background: #f3f3f3e8;
  overflow-y: auto !important;
  height: 100vh !important;
  background-color: rgb(255, 255, 255);
}

.datoin-signup .emlyLogoMb {
  display: none;
}

.datoin-signup body {
  background: #f3f3f3e8;
}

.datoin-signup .modal-content {
  border: none;
  border-radius: 0px;
  box-shadow: 1px 6px 10px 3px #9c9c9cc7;
}

.datoin-signup .signup-left-panel {
  background-position: bottom;
  background-size: cover;
  background-color: #8c8c8c42;
  background-blend-mode: multiply;
}

.datoin-signup .signup-right-panel {
  padding: 40px;
  min-height: 510px;
}

.datoin-signup .signup-right-panel button {
  margin-top: 10px;
  border-radius: 1.75em;
}

.datoin-signup .signup-right-panel h3 {
  color: #ff6120;
  text-align: center;
}

.datoin-signup .form-group {
  margin-bottom: 0.5rem;
  padding-right: 0px;
}

.datoin-signup .form-control {
  height: auto;
  padding: 0.5rem 0.22rem;
  margin-top: 0.12rem;
  margin-bottom: 0.5rem;
}

.datoin-signup .form-control input {
  height: auto;
}

.datoin-signup label {
  margin-bottom: 0.125rem;
  font-size: 10rem;
}

.datoin-signup .deep-blue-text {
  color: #545454 !important;
}

.datoin-signup #signUpModal .modal-dialog {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.datoin-signup .hide {
  display: none;
}

.datoin-signup .show {
  display: block;
}

.datoin-signup .text-centre {
  display: flex;
  justify-content: center;
}

.datoin-signup #datoin-header-bg {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.225);
  background: #29323ce8;
  border: 0px;
  width: 100%;
}

.datoin-signup div#footer {
  background-color: #261f2f;
  padding: 20px 0 10px 0;
  /* box-shadow: 0px -6px 11px 0 rgba(0,0,0,.225); */
  position: relative;
  bottom: 0px;
  width: 100%;
  color: white;
}

.datoin-signup .fixedwidth {
  max-width: 1200px;
  margin: 0px auto;
  float: none;
}

.datoin-signup #footer a.hglight,
a.blue-anchor {
  color: #97dffd;
  display: inline-block;
  margin: 0;
  text-decoration: none;
  font-weight: 100 !important;
  font-size: 15px;
  font-family: 'redHatRegular';
}

.datoin-signup #footer a.hglight:after,
a.blue-anchor:after {
  display: block;
  content: '';
  border-bottom: solid 1.2px #25cce4;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.datoin-signup #footer a.hglight:hover:after,
a.blue-anchor:hover:after,
a.text-head:hover:after,
a.person-card:hover .b-card__title:after {
  transform: scaleX(1);
  color: #25cce4;
}

.datoin-signup .footer-group h4 {
  border-style: none;
  border-width: 0 0 1px;
  color: #fff;
  font-size: 19px;
  text-transform: uppercase;
}

.datoin-signup #footer a.logo-link img {
  height: 1.1em;
}

.datoin-signup .row {
  display: flex;
  flex-wrap: wrap;
}

.datoin-signup .form-control {
  padding: 0.5rem 0 0.6rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: transparent;
  background-image: none;
  border-radius: 0;
  margin-top: 0.12rem;
  margin-bottom: 0.5rem;
}

.datoin-signup .navbar {
  z-index: 9999;
}

.datoin-signup .navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.datoin-signup .footer-text {
  font-size: 14px;
  font-weight: bold;
}

.datoin-signup label {
  font-size: 1.1rem;
  font-weight: 500;
}

.datoin-signup .input {
  height: 40px;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  outline: 0;
  width: 100% !important;
  font-size: 1rem;
  box-shadow: none;
  box-sizing: border-box;
  transition: all 0.3s;
  padding: 5px 10px !important;
  border-radius: 5px;
  margin: 15px 0;
}

.datoin-signup .input::placeholder {
  color: black;
  font-family: 'redHatBold' !important;
  font-style: normal;
}

.datoin-signup #signUpForm .css-yk16xz-control,
.datoin-signup #signUpForm .css-1pahdxg-control {
  height: 40px;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  outline: 0;
  width: 100% !important;
  font-size: 1rem;
  box-shadow: none;
  box-sizing: border-box;
  transition: all 0.3s;
  border-radius: 5px;
  margin: 10px 0;
}

.datoin-signup .status {
  float: right;
}

.datoin-signup .statusPrice {
  font-size: 40px;
  font-weight: bold;
  line-height: 0.7;
}

.datoin-signup .perUser {
  font-size: 8px;
}

.datoin-signup .statusName {
  font-size: 11px;
  font-weight: bold;
  margin-left: 3px;
}

.datoin-signup .card {
  background-color: #fff;
  /* box-shadow: 0px 2px 6px #5CCBB5; */
  border-radius: 10px;
  padding: 5px;
  width: 150px;
  border: 1px solid lightgrey;
}

.datoin-signup .emaill {
  margin-left: 20px;
}

.datoin-signup .terms {
  font-size: 10px !important;
  color: #000 !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  border-bottom: 1px solid hsl(0, 0%, 80%);
}

.datoin-signup .em-row.termsCheck svg {
  fill: var(--themeColor) !important;
}

.datoin-signup .terms:hover {
  font-size: 10px !important;
  color: #5ccbb5 !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  border-bottom: 1px solid #5ccbb5;
}

.datoin-signup .card-active {
  background-color: #5ccbb5;
  box-shadow: 0px 2px 6px pink;
  border-radius: 10px;
  padding: 20px;
  border-width: 0px;
  width: 150px;
  height: 120px;
  color: #fff;
}

.datoin-signup .img {
  width: 35px;
  height: 35px;
}

.datoin-signup a.logo-link {
  font-size: 32px;
}

.datoin-signup .hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.datoin-signup .submit-button {
  width: 100%;
  float: left;
  margin: 5px 0 !important;
  border-radius: 5px !important;
}

.datoin-signup .goback-button {
  width: 100%;
}

.datoin-signup .footerText {
  text-align: center;
  font-weight: 550;
}

.datoin-signup .checkboxInput {
  /* margin-top: 13px; */
  font-size: 11px;
}

/* Style the placeholder text color */
.datoin-signup ::placeholder {
  color: #999;
  font-family: 'redHatMedium';
  font-size: 10px;
  opacity: 0.5;
  text-decoration: none !important;
  margin-left: 5px;
}

.datoin-signup .plans {
  color: #fff;
  border-color: #fff;
}

.datoin-signup .terms {
  margin-top: 5px;
  cursor: pointer;
}

.datoin-signup .termsCheck {
  display: flex;
  align-items: center;
  margin: 20px 0 10px;
}

.datoin-signup .helper {
  font-size: 9px;
  color: gray;
  float: left;
}

.datoin-signup .alert {
  position: relative;
  font-family: 'redHatBold';
  padding: 1.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 16px;
}

.datoin-signup .alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.datoin-signup .alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

#termsCheck {
  width: 20px;
  height: 20px;
}

.loader {
  border: 8px solid #e0dfdf;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 3em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.datoin-signup .register {
  color: #363636;
  float: left;
  font-size: 19px;
  font-weight: 600;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  margin: 0 0 10px;
}

.datoin-signup .register.verify {
  font-size: 24px;
}

.datoin-signup .Text-component {
  float: left;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 60%;
  flex-direction: column;
  gap: 10px;
}

.datoin-signup .main {
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: center;
  border: 1px solid rgba(144, 163, 191, 0.3);
  border-radius: 6px;
  padding: 20px;
}

.datoin-signup .welcome {
  margin-right: 4%;
  font-size: 12px;
  color: grey;
}

.datoin-signup .box {
  display: flex;
  /* flex: 1; */
  /* align-items: center; */
  /* justify-content: center; */
  background-color: white;
}

.datoin-signup .top {
  position: absolute;
  right: 15px;
  top: 15px;
  clear: both;
}

.datoin-signup .already {
  color: #000;
  font-size: 11px;
  font-weight: 600;
  margin-right: 10px;
  font-family: 'redHatMedium';
  z-index: 99;
}

.datoin-signup .left {
  display: flex;
  flex-direction: column;
}

.datoin-signup .link {
  font-size: 9px;
  font-weight: bold;
  float: right;
  color: #1ab4ba;
}

.datoin-signup .error-div {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.datoin-signup .sorry {
  text-align: left;
  font-size: 12px;
  margin-top: 20px;
  color: grey;
}

.datoin-signup .file-progress-sec {
  width: 100%;
}

.u-singup-div {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.em-link.privacy {
  color: #0088ce !important;
  text-decoration: underline;
}

.datoin-signup .image-div {
  width: 40%;
  background: url(../../../images/SignupImages/BackGroundImage.png);
  display: flex;
  background-size: cover;
}

.datoin-signup .contnet-main {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  color: #fff;
}

.datoin-signup .content-div {
  width: 100%;
  height: 100vh;
  align-items: center;
  display: flex;
  padding: 0 10%;
  flex-direction: column;
}

.datoin-signup .content-div.signup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.datoin-signup .content-div.signup .all-content {
  width: 100%;
  position: relative;
  clear: both;
  padding: 0;
  margin: 0;
}

.datoin-signup .content-div.signup .all-content .h1-title {
  color: #ffffff;
  font-size: 40px;
  font-family: 'redHatBold';
  line-height: normal;
}

.datoin-signup .content-div.signup .all-content .h3-helper {
  color: darkorange;
  font-size: 22px;
  font-family: 'redHatBold';
  line-height: normal;
  padding: 10px 0;
}

.datoin-signup .content-div.signup .all-content .h1-title.isLearner {
  color: darkorange !important;
  font-size: 35px;
  font-family: 'redHatBold';
  line-height: normal;
  text-align: center;
}

.datoin-signup .content-div.signup .all-content .h3-helper.isLearner {
  color: #ffffff !important;
  font-size: 25px;
  font-family: 'redHatBold';
  line-height: normal;
  padding: 10px 0;
  text-align: center;
}

.datoin-signup .content-div.signup .all-content .isLearner-title {
  width: 100%;
  font-size: 40px;
  margin: 40px 0 0;
  padding: 0;
  color: #5ccbb5;
  font-family: 'redHatBold';
  float: left;
}

.datoin-signup .content-div.signup .all-content .ul-list ul {
  margin: 0;
  padding: 50px 0 0;
  list-style: none;
  color: #ffffff;
  font-size: 16px;
  font-family: 'redHatRegular';
  line-height: normal;
}

.datoin-signup .content-div.signup .all-content .ul-list ul.isLearner {
  padding: 0 !important;
}

.datoin-signup .content-div.signup .all-content .ul-list ul li {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 22px;
  padding: 10px 0;
  font-family: 'redHatMedium';
}

.datoin-signup .content-div.signup .all-content .ul-list ul li img {
  height: 40px;
  align-self: 'flex-start';
}

.datoin-signup .content-div.signup .all-content .icon-signup-logo-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 0 0;
}

.datoin-signup .content-div.signup .all-content .icon-signup-logo {
  height: 30px;
}

.datoin-signup a {
  color: #5ccbb5;
}

.em-row.org-logout .topicons {
  justify-content: end !important;
  background: white !important;
  padding: 15px 10px 0 !important;
}

#alreadyLogin {
  width: 100%;
  position: relative;
  float: left;
  margin-top: 10px;
}

#alreadyLogin span {
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: 'redHatMedium';
  color: rgb(128, 128, 128);
}

#alreadyLogin span a {
  font-size: 11px;
  color: #0088ce !important;
  text-decoration: underline;
}

.em-flex-success .register {
  text-align: center !important;
}

.em-flex-success span.left {
  text-align: center !important;
  font-size: 16px;
}

.em-flex-success {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.em-flex-success span.bd {
  font-family: 'redHatBold';
  font-size: 16px;
}

.em-flex-success .email-verify-note {
  text-align: left;
  font-size: 13px;
}

.em-flex-success .email-verify-note a.bd {
  color: #0088ce !important;
  font-family: 'redHatBold';
}


.datoin-signup .learner-label-title {
  margin: 0;
  font-size: 17px;
  font-family: 'redHatBold';
  padding: 0;
}
:root {
  --themeColor: #5ccbb5;
  --foreGroundColor: #ffffff;
  --boxShadow: rgb(111 235 224);
  --fontFamily: 'redHatBold';
  --blackColor: #2d3748;
  --cardHue: 119deg;
  --cardHuePrimary: 369deg;
  --grayscale: none;
  --boxShadowMyApps: rgb(111 235 224 / 63%);
  --shade: #c6e9e66b;
  --grayColor: #90a3bf;
  --borderRadius: 6px;
  --hoverShade: #add7ce;
}

[data-theme='dark'] {
  --themeColor: #23a6f0;
  --boxShadow: #23a6f0;
  --foreGroundColor: #ffffff;
  --fontFamily: 'redHatExtraBold';
  --blackColor: #2d3748;
  --cardHue: 49deg;
  --cardHuePrimary: 49deg;
  --grayscale: 1;
  --boxShadowMyApps: rgb(84 191 223 / 50%);
  --shade: #b3dcf345;
  --grayColor: #90a3bf;
  --borderRadius: 1px;
  --hoverShade: #aacadc;
}

[data-theme='light'] {
  --themeColor: #5ccbb5;
  --foreGroundColor: #ffffff;
  --boxShadow: rgb(111 235 224);
  --fontFamily: 'redHatBold';
  --blackColor: #2d3748;
  --cardHue: 119deg;
  --cardHuePrimary: 369deg;
  --grayscale: none;
  --boxShadowMyApps: rgb(111 235 224 / 63%);
  --shade: #c6e9e66b;
  --grayColor: #90a3bf;
  --borderRadius: 6px;
  --hoverShade: #add7ce;
}

[data-theme='extra-dark'] {
  --themeColor: #2d3748;
  --foreGroundColor: #ffffff;
  --boxShadow: #2d3748;
  --fontFamily: 'redHatExtraBold';
  --blackColor: #2d3748;
  --cardHue: none;
  --cardHuePrimary: none;
  --grayscale: none;
  --boxShadowMyApps: #2d374838;
  --shade: #2d37481c;
  --grayColor: #90a3bf;
  --borderRadius: 20px;
  --hoverShade: #474d57;
}

@import-normalize;

/* importing 10 out of 14 redhat  fomt-family style */
@font-face {
  font-family: 'redHatBold';
  src: local('redHatBold');
  src: url('./fonts/RedHatDisplay-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'redHatExtraBold';
  src: local('redHatExtraBold');
  src: url('./fonts/RedHatDisplay-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: 'redHatLight';
  src: local('redHatLight');
  src: url('./fonts/RedHatDisplay-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'redHatMedium';
  src: local('redHatMedium');
  src: url('./fonts/RedHatDisplay-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'redHatRegular';
  src: local('redHatRegular');
  src: url('./fonts/RedHatDisplay-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'redHatSemiBold';
  src: local('redHatSemiBold');
  src: url('./fonts/RedHatDisplay-SemiBold.woff2') format('woff2');
}

html {
  overflow: hidden;
}

body {
  overflow: hidden !important;
}

.mainDashboardConatiner {
  width: 100%;
  position: relative;
  height: 100vh;
  float: left;
  clear: both;
  overflow: hidden;
}

.mainDashboardConatiner #shade {
  position: absolute;
  bottom: 0%;
  height: 20px;
  border-radius: 0px 0px 0px 0px;
  width: 100%;
  background: linear-gradient(to bottom, #ffffff14 0%, var(--shade));
}

.mainDashboardConatiner .LeftSidePanel {
  width: 100%;
  float: left;
  position: relative;
  padding: 0px 5px 10px 10px;
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
}

.logo-datoin {
  width: 100%;
  float: left;
  margin: 0 auto;
  display: block;
  position: relative;
  margin-top: 3px;
  z-index: 1;
}

.mainDashboardConatiner .LeftSidePanel.collapsed {
  padding: 0px 5px 10px 10px;
}

.mainDashboardConatiner .LeftSidePanel .quick-flowLinks {
  border-top: 2px solid #a9a9a95e;
  float: left;
  margin-top: 10px;
  padding-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.LeftSidePanel {
  position: relative;
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.LeftSidePanel .em-items {
  position: relative;
  width: 100%;
  clear: both;
  float: left;
}

.LeftSidePanel .em-items svg#emly-icn {
  width: 32px;
  padding: 0px 4px;
  text-align: center;
  color: #5ccbb5;
  color: var(--themeColor);
  border-radius: 10px;
}

.LeftSidePanel .em-items svg#emly-icn.phome {
  margin-right: 5px;
}

.LeftSidePanel .em-items.bottom {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.LeftSidePanel .em-items.bottom .nav-item.version {
  background: rgb(144 163 191 / 5%);
  box-shadow: 0px 3.5px 5.5px rgb(0 0 0 / 2%);
  border-radius: 6px;
  color: #2d3748;
  padding: 7px;
  gap: 2px;
}

.collapsibleIcon {
  float: left;
  margin-right: 20px !important;
}

.mainDashboardConatiner .LeftSidePanel.collapsed .collapsibleIcon {
  position: relative;
  width: 100%;
  float: left;
  margin: 15px 0;
  padding: 0;
}

.mainDashboardConatiner .LeftSidePanel.collapsed .nav-item {
  width: max-content;

  border-radius: var(--borderRadius);
}

.mainDashboardConatiner .LeftSidePanel.collapsed .name-case {
  display: none;
}

.mainDashboardConatiner .LeftSidePanel.collapsed label.nav-item#Account-page-section {
  display: none;
}

.mainDashboardConatiner .LeftSidePanel .nav-item {
  float: left;
  width: 95%;
  user-select: none;
  position: relative;
  line-height: normal;
  color: #90a3bf;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 5px 0;
  font-size: 11px;
  font-weight: 700;
  font-family: system-ui;
  border-radius: 6px !important;
}

.mainDashboardConatiner .LeftSidePanel .nav-item.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.mainDashboardConatiner .LeftSidePanel .nav-item.removeColor {
  color: black;
}

/* styling scroll bar for tab items */
/* style scroll bars */

.mainDashboardConatiner .LeftSidePanel::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}


.mainDashboardConatiner .LeftSidePanel .nav-item:active {
  background: #ffff;
  border-radius: 15px;
  color: #2d3748;
}

.mainDashboardConatiner .LeftSidePanel .nav-item.selected {
  background: rgba(144, 163, 191, 0.19);
  box-shadow: 0px 3.5px 5.5px rgb(0 0 0 / 2%);
  border-radius: 6px;
  color: #2d3748;
}

.mainDashboardConatiner .LeftSidePanel .nav-item.home {
  padding: 5px;
  background: var(--themeColor);
  box-shadow: 0px 3.5px 5.5px rgb(0 0 0 / 2%);
  border-radius: 15px;
  color: #fff;
  margin-bottom: 10px;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .select-drop-drown .select-div .fa {
  color: var(--themeColor);
}

.mainDashboardConatiner .LeftSidePanel .project-Section .select-your-project {
  overflow: hidden;
  width: 90%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .select-drop-drown .fa.fa-caret-right {
  width: 28px;
  padding: 7px 7px 7px 7px;
  text-align: center;
  background: #ffff;
  color: var(--themeColor);
  border-radius: 20px;
}

.mainDashboardConatiner .LeftSidePanel .nav-item .fa {
  width: 36px;
  padding: 6px 6px;
  text-align: center;
  color: var(--themeColor);
  /* background: #ffff; */
  border-radius: 10px;
}

/* change icons color when selected  */

/* style for body */
.mainDashboardConatiner .right-body-panel {
  background: #ffff;
  width: calc(100vw - 170px);
  max-height: 100%;
  padding: 15px 10px 20px 20px;
}

.mainDashboardConatiner .right-body-panel.collapsed {
  width: calc(100vw - 50px);
}

/* style scroll bars */

.mainDashboardConatiner div.right-body-panel::-webkit-scrollbar {
  height: 5px;
  width: 10px;
}

/* Track */

.mainDashboardConatiner div.right-body-panel::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */

.mainDashboardConatiner div.right-body-panel::-webkit-scrollbar-thumb {
  background: rgb(197, 191, 191);
  border-radius: 20px;
}

/* Handle on hover */

.mainDashboardConatiner div.right-body-panel::-webkit-scrollbar-thumb:hover {
  background: rgb(197, 191, 191);
}

/* style for body */

.mainDashboardConatiner .scrollable-sec {
  height: calc(100% - 100px);
  width: 98%;
  float: left;
  padding: 0px;
  position: relative;
  overflow-y: hidden;
}

.mainDashboardConatiner .scrollable-sec:hover {
  overflow-y: auto;
}

/* style scroll bars */

.mainDashboardConatiner .scrollable-sec::-webkit-scrollbar {
  height: 5px;
  width: 15px;
}

/* Track */

.mainDashboardConatiner .scrollable-sec::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */

.mainDashboardConatiner .scrollable-sec::-webkit-scrollbar-thumb {
  background: var(--themeColor);
  border-radius: 20px;
}

/* Handle on hover */

.mainDashboardConatiner .scrollable-sec::-webkit-scrollbar-thumb:hover {
  background: var(--themeColor);
}

/* style count of apps over tab menu for my apps and appstore */
.mainDashboardConatiner .LeftSidePanel .nav-item .count-total-apps {
  width: 21px;
  text-align: center;
  padding: 5px;
  background: var(--themeColor);
  top: -5px;
  right: 5px;
  position: absolute;
  border-radius: 50px;
  color: #ffff;
  height: 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mainDashboardConatiner .right-body-panel .scrollable-div-contents {
  height: calc(100vh - 140px);
  overflow-y: auto;
  position: relative;
  width: 100%;
  padding: 0px;
}

.mainDashboardConatiner .right-body-panel .scrollable-div-contents::-webkit-scrollbar {
  height: 5px !important;
  width: 10px !important;
}

/* Track */

.mainDashboardConatiner .right-body-panel .scrollable-div-contents::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */

.mainDashboardConatiner .right-body-panel .scrollable-div-contents::-webkit-scrollbar-thumb {
  background: var(--themeColor);
  border-radius: 20px;
}

/* Handle on hover */

.mainDashboardConatiner .right-body-panel .scrollable-div-contents::-webkit-scrollbar-thumb:hover {
  background: var(--themeColor);
}

@media only screen and (max-width: 767px) {
  .collapsibleIcon {
    display: none;
  }

  .Horizonatal-line {
    display: none;
  }

  .logo-datoin {
    width: 100%;
    float: left;
    margin: 0 auto;
    display: block;
    position: relative;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 8px;
    z-index: 1;
  }

  .logo-datoin-img {
    /* filter: hue-rotate(var(--cardHue)); */
    width: 50px;
    height: 20px;
  }

  .mainDashboardConatiner .LeftSidePanel {
    width: 100%;
    padding: 0;
    /* background: darkslategrey; */
  }

  .mainDashboardConatiner .LeftSidePanel label.nav-menu-item#menu {
    display: none;
  }

  .mainDashboardConatiner .LeftSidePanel label.nav-menu-item#account {
    display: none;
  }

  .mainDashboardConatiner .scrollable-sec {
    height: auto;
    padding: 0;
    overflow: hidden;
  }

  .mainDashboardConatiner .LeftSidePanel .nav-item {
    text-align: center;
  }

  .mainDashboardConatiner .right-body-panel .admin-title-breadcrumb-parent {
    flex-direction: column;
  }

  .mainDashboardConatiner .right-body-panel .select-bar-with-space-card-parent {
    flex-direction: column;
  }

  .mainDashboardConatiner .right-body-panel .scrollable-div-contents {
    float: left;
    height: calc(100vh - 155px);
  }

  .mainDashboardConatiner .right-body-panel .select-bar-with-space-card-parent .space-quota-card {
    width: 100%;
    margin: 20px 0px 0px;
  }

  .mainDashboardConatiner .right-body-panel .cards-parent-div {
    flex-direction: column;
  }

  .mainDashboardConatiner .right-body-panel .cards-parent-div .card {
    width: 100%;
    flex-direction: column;
  }
}

/* theme chnages */
/* change colr */
.collapsibleIcon img {
  filter: hue-rotate(var(--cardHue));
}

/* change color */
.logo-datoin-img {
  filter: hue-rotate(var(--cardHue));
  width: 90px;
  height: 20px;
}

/* add custom burger menu */
#burger-menu {
  background-color: var(--themeColor);
  cursor: pointer;
  margin-right: -10px;
  padding: 4px 6px 2px 6px;
  color: #fff;
  border-radius: var(--borderRadius);
}

#line1,
#line2,
#line3 {
  background-color: #ffffff;
  width: 100%;
  height: 2px;
  border-radius: 10px;
}

.mainDashboardConatiner .LeftSidePanel .project-Section {
  position: relative;
  float: left;
  width: 100%;
  margin: 10px 0;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .layer-one {
  border-radius: 15px;
  width: 100%;
  color: #90a3bf;
  margin: 10px 0;
  font-weight: 700;
  font-family: system-ui;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .layer-one.selected {
  background: #5ccbb5;
  padding: 7px 5px;
  color: #fff;
  background: var(--themeColor);
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 15px;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .layer-one .top-Item {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  width: 100%;
  user-select: none;
  cursor: pointer;
  font-size: 11px;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .layer-one .top-Item.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: pointer !important;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .layer-one .top-Item .projectIcon {
  background: white;
  padding: 2px 8px;
  border-radius: 16px;
  color: var(--themeColor);
}

.mainDashboardConatiner .LeftSidePanel .project-Section .layer-one .top-Item .projectIcon .fa-lightbulb-o {
  font-size: 16px;
  font-weight: 700;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .layer-one .top-Item .projectIcon img {
  width: 100%;
  padding: 5px;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .select-drop-drown {
  float: left;
  position: relative;
  width: 90%;
  margin-left: 0px;
  margin-top: 5px;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .select-drop-drown .disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: pointer !important;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .select-drop-drown .select-div {
  line-height: normal;
  position: relative;
  padding: 5px 0;
  font-size: 11px;
  font-weight: 700;
  font-family: system-ui;
  color: #90a3bf;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .select-drop-drown .select-div.selected {
  padding: 7px 5px;
  color: #fff;
  user-select: none;
  background: var(--themeColor);
  box-shadow: 0px 3.5px 5.5px rgb(0 0 0 / 2%);
  border-radius: 15px;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .select-drop-drown .select-div.selected a {
  color: #fff;
  width: calc(100% - 60px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .select-drop-drown .select-div.selected .fa {
  width: 20px;
  padding: 5px 0px 5px 0px;
  text-align: center;
  background: #ffff;
  color: var(--themeColor);
  border-radius: 20px;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .select-drop-drown .select-div.selected :last-child {
  margin-left: auto;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .select-drop-drown .select-div.opendropdown {
  color: #3a556a;
  border-radius: 0px;
  background: white;
  padding: 7px 5px;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .select-drop-drown .select-div.opendropdown a {
  color: #3a556a;
  width: calc(100% - 60px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .select-drop-drown .select-div.opendropdown .fa {
  width: 25px;
  background: lightgray;
  color: #3a556a;
  padding: 5px 5px;
  text-align: center;
  border-radius: 20px;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .select-drop-drown .select-div.opendropdown :last-child {
  margin-left: auto;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .select-drop-drown .projects {
  padding: 5px 5px 5px 30px;
  position: absolute;
  top: 30px;
  z-index: 9999;
  width: 100%;
  overflow-y: auto;
  max-height: 170px;
  user-select: none;
  left: 0px;
  background: #ffffff;
  box-shadow: 0px 3.5px 5.5px rgb(0 0 0 / 2%);
}

.mainDashboardConatiner .LeftSidePanel .project-Section .select-drop-drown .projects .item {
  padding: 3px 9px;
  color: #90a3bf;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .projects .item:hover {
  background: #90a3bf;
  color: #ffffff;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .projects .item.selected {
  background: var(--themeColor);
  color: white;
  cursor: default;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .projects::-webkit-scrollbar {
  width: 10px;
  cursor: hand;
}

.mainDashboardConatiner .LeftSidePanel .project-Section .projects::-webkit-scrollbar-track {
  background: #ffffff;
  /* color of the tracking area */
}

.mainDashboardConatiner .LeftSidePanel .project-Section .projects::-webkit-scrollbar-thumb {
  background-color: var(--themeColor);
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}

/* Breadcrumb Css here */

.mainDashboardConatiner .right-body-panel .admin-title-breadcrumb-parent {
  position: relative;
  width: 100%;
  float: left;
  margin-bottom: 15px;
}

.mainDashboardConatiner .right-body-panel .admin-title-breadcrumb-parent .admin-panel-title {
  color: #2d3748 !important;
  font-weight: bold;
  font-size: 17px;
  font-family: system-ui;
  position: relative;
  float: left;
  width: 100%;
  /* margin-bottom: 10px; */
}

.mainDashboardConatiner .right-body-panel .admin-title-breadcrumb-parent .breadcrumb {
  padding: 0px;
  margin: 0;
  display: flex;
  align-items: center;

  font-size: 12px;

  color: gray;
  cursor: pointer;

  font-weight: bold;
}

.mainDashboardConatiner .right-body-panel .admin-title-breadcrumb-parent .admin-panel-breadcrumb {
  position: relative;
  float: left;
  width: 100%;
}

.mainDashboardConatiner .right-body-panel .admin-title-breadcrumb-parent .admin-panel-breadcrumb {
  color: #a3a8ae;
}

.mainDashboardConatiner .right-body-panel .admin-title-breadcrumb-parent .admin-panel-breadcrumb .linkIcon {
  color: #a3a8ae;
}

.mainDashboardConatiner .right-body-panel .admin-title-breadcrumb-parent .admin-panel-breadcrumb .linkName {
  color: gray;
  font-weight: bold;
}

.logo-div {
  display: flex;
  flex-direction: row;
  z-index: 1;
}

.mainDashboardConatiner .right-body-panel .admin-title-breadcrumb-parent .admin-panel-breadcrumb .active .activelinkIcon {
  margin-right: 5px;
}

.mainDashboardConatiner .right-body-panel .admin-title-breadcrumb-parent .admin-panel-breadcrumb .active {
  color: var(--themeColor);
}

.btn.btn-lg.btn-primary.upload-btn-modal {
  background: var(--themeColor);
  background-color: var(--themeColor);
  background-image: none;
  border: none;
}

.o-p-L-ic-ld {
  position: fixed;
  z-index: 10000;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  color: #fff;
  overflow: auto;
  background: #00000070;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.o-p-L-ic-ld strong {
  color: #ffffff;
}

.o-p-L-ic-wc {
  position: absolute;
  z-index: 10000;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  color: var(--themeColor);
  overflow: auto;
  background: #00000000;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.o-p-L-ic-md {
  z-index: 10000;
  left: 0px;
  top: 0px;
  height: 100vh;
  width: 100%;
  color: #fff;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.o-p-L-ic-md strong {
  color: #000000;
}

.instance_down {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: relative;
  height: 34px;
  color: #ef3636;
  background-color: #fcd462;
  width: 100%;
  overflow: hidden;
  float: left;
  text-align: center;
  border-radius: var(--borderRadius);
  font-family: 'redHatBold';
  padding: 15px 12px;
  margin: 10px 0 0;
}

.higlightMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: relative;
  height: 34px;
  color: #ef3636;
  background-color: #fcd462;
  width: 100%;
  overflow: hidden;
  float: left;
  text-align: center;
  border-radius: var(--borderRadius);
  font-family: 'redHatBold';
  padding: 5px 12px;
  margin: 0 px;
}

.right-body-panel.collapsed .instance_down {
  width: calc(100vw - 70px);
}

.instance_down .restore {
  text-decoration: underline;
  color: #000000;
  cursor: pointer;
}

.not-permitted {
  width: 100%;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: 'redHatRegular';
  color: #ef3636;
  font-weight: 800;
}

.np-permission-matrix {
  pointer-events: none;
  opacity: 0.5;
  position: relative;
}

.np-permission-matrix:before {
  content: 'Not Permitted';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  margin-right: 15px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  text-align: center;
  display: none;
}

.np-permission-matrix:hover:before {
  display: block;
}

div#not-selected-org .np-permission-matrix {
  pointer-events: none;
  opacity: unset;
}

div#not-selected-org a {
  color: #a3a8ae;
  cursor: not-allowed;
  pointer-events: none;
}

div#not-selected-org {
  color: #a3a8ae;
  cursor: not-allowed;
  background-color: #edebeb;
  pointer-events: none;
}

/* Following needs to be move  */
.emly-container {
  position: relative;
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
}

.empad20 {
  padding: 20px;
}

.emly-center {
  width: 100%;
  float: left;
  padding: 0;
  margin: 50px 0 0 0;
  clear: both;
  text-align: center;
  min-height: calc(100vh - 170px);
}

.emly-center .error-item {
  font-size: 16px;
  width: 100%;
  margin: 10px auto;
  font-family: 'redHatBold';
  float: left;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  font-weight: 600;
}

.emly-btn {
  padding: 0px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  float: left;
}

.emly-btn-danger {
  color: #ffffff;
  background-color: #ff0000;
}

.cover-body-full {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 10%;
  background-color: rgba(0, 0, 0, 0.3);
}

.emly-disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: pointer !important;
}

.superbtn {
  background-color: white;
  font-family: 'redHatBold';
  border-radius: 10px !important;
  padding: 5px 100px;
  font-size: 13px !important;
}

.superset-btn-block {
  color: white;
  background: var(--themeColor);
  border: none;
  border-radius: 4px;
  min-width: 100px;
  padding: 7px 7px;
  font-family: 'redHatBold';
  cursor: pointer;
  text-align: center;
  content: icon;
  float: right;
}

.datoin-row ul {
  margin: 0px;
  padding: 0;
}

.datoin-row ul li {
  list-style: none;
  margin: 10px 0;
}

.selected-list {
  background-color: lightblue;
  width: 100%;
}

/* CustomSelect.css */
.custom-select-container {
  /* position: relative; */
}

.custom-select-label {
  position: absolute;
  top: -5px;
  /* Adjust this value as needed */
  left: 0;
  background-color: white;
  /* Match your background color */
  padding: 0 5px;
  font-weight: bold;
  z-index: 1;
}

.custom-select {
  border-top: 1px solid #ccc;
  /* Add a top border to the select component */
  margin-top: 10px;
  /* Adjust this value to leave space for the title */
}

.ds h1 {
  color: #363636;
  font-family: 'redHatMedium';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0;
  margin: 0;
}

.ds h2 {
  color: #363636;
  font-family: 'redHatMedium';
  font-size: 13px;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.vsSwitch .react-switch-bg {
  height: 20px !important;
  width: 50px !important;
}

.vsSwitch .react-switch-bg div {
  height: 22px !important;
}

.vsSwitch .react-switch-bg div svg {
  width: 30px !important;
}

.vsSwitch .react-switch-handle {
  height: 18px !important;
  width: 18px !important;
}

.em-scroll {
  float: left;
  position: relative;
  overflow-y: auto;
}

.em-scroll::-webkit-scrollbar {
  width: 10px;
  cursor: hand;
}

.em-scroll::-webkit-scrollbar-track {
  background: #ffffff;
}

.em-scroll::-webkit-scrollbar-thumb {
  background-color: var(--themeColor);
  border-radius: 20px;
}

/* style tcom form input */
.fieldset .form-group input {
  border-radius: var(--borderRadius);
}

.fieldset .form-group select {
  border-radius: var(--borderRadius) !important;
}

.txtHover:hover {
  opacity: 0.8;
}

.txtUnderLin:hover {
  text-decoration: underline;
}

/* for general icons */
.ltst-line {
  border-left: 2px solid #90a3bf7d;
  align-self: stretch;
  margin: 4px 0px;
  justify-content: center;
}

.ltst-help-icn {
  width: 12px;
  padding: 0;
}

.icon-Img img {
  width: 12px;
  padding: 0;
  cursor: pointer;
}

/* css for button */
button {
  border-radius: var(--borderRadius) !important;
}

.buildAppModalInferH2 {
  color: rgb(85, 85, 85);
  font-family: 'redHatMedium';
  font-weight: 500;
  letter-spacing: normal;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 5px 0 15px !important;
}

.h3style {
  margin: 10px 0 !important;
}

.em-close-icn-button {
  text-align: center;
  padding: 6px;
  cursor: pointer;
  background: #2d3748 !important;
  color: #ffff !important;
  border: 0 !important;
  width: 25px !important;
  border-radius: 30px !important;
  float: right;
}

.em-row.refresh-compo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 15px;
}

.em-row.refresh-compo .fa {
  font-size: 30px;
  color: #2d3748 !important;
}

.em-row.refresh-compo .message-refresh {
  font-size: 18px;
  color: #ef3636;
  float: left;
  width: 100%;
}

.profile-UCrrentIn tr,
.profile-UCrrentIn td {
  background-color: var(--themeColor);
  color: var(--foreGroundColor) !important;
}

.ErrorBParent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ErrorB {
  float: none;
  position: relative;
  width: 600px;
  padding: 25px;
  background: #efefef;
  box-shadow: 2px 3px 8px 1px grey;
  border-radius: 8px;
}

.ErrorB svg {
  width: 30px;
  height: 30px;
}


.ErrorB h1 {
  font-family: 'redHatMedium';
  font-size: 28px;
  margin: 0;
}

.ErrorB .inn-cop {
  font-family: 'redHatRegular';
  font-size: 15px;
  margin: 10px 0;
  color: #0b1018;
}

.ErrorB .inn-h3 {
  font-family: 'redHatMedium';
  font-size: 15px;
  margin: 10px 0;
  color: #0b1018;
}

.ErrorB .err-reload {
  justify-content: center;
  display: flex;
  margin: 5px 0 0;
}

.ErrorB .err-reload button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.tcomb-textarea-desc {
  min-width: 100%;
  max-width: 100%;
  max-height: 400px;
  min-height: 30px;
}

.tcomb-textarea-desc.build-app {
  min-height: 100px !important;
}

.tcomb-textarea-desc.user {
  font-size: 17px !important;
}

.waitingLoaderParent {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0;
  margin: 0;
  background-color: rgb(0 0 0 / 9%);
  display: flex;
  justify-content: center;
  align-items: center;
}
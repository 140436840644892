:root {
  --themeColor: #5ccbb5;
  --boxShadow: rgb(111 235 224);
  --fontFamily: 'redHatBold';
  --blackColor: #2d3748;
  --cardHue: 119deg;
  --cardHuePrimary: 369deg;
  --grayscale: none;
  --boxShadowMyApps: rgb(111 235 224 / 63%);
  --shade: #c6e9e66b;
  --grayColor: #90a3bf;
}

[data-theme='dark'] {
  --themeColor: #23a6f0;
  --boxShadow: #23a6f0;
  --fontFamily: 'redHatExtraBold';
  --blackColor: #2d3748;
  --cardHue: 49deg;
  --cardHuePrimary: 49deg;
  --grayscale: 1;
  --boxShadowMyApps: rgb(84 191 223 / 50%);
  --shade: #b3dcf345;
  --grayColor: #90a3bf;
}

[data-theme='light'] {
  --themeColor: #5ccbb5;
  --boxShadow: rgb(111 235 224);
  --fontFamily: 'redHatBold';
  --blackColor: #2d3748;
  --cardHue: 119deg;
  --cardHuePrimary: 369deg;
  --grayscale: none;
  --boxShadowMyApps: rgb(111 235 224 / 63%);
  --shade: #c6e9e66b;
  --grayColor: #90a3bf;
}

[data-theme='extra-dark'] {
  --themeColor: #2d3748;
  --boxShadow: #2d3748;
  --fontFamily: 'redHatExtraBold';
  --blackColor: #2d3748;
  --cardHue: none;
  --cardHuePrimary: none;
  --grayscale: none;
  --boxShadowMyApps: #2d374838;
  --shade: #2d37481c;
  --grayColor: #90a3bf;
}

.emly-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.1);  */
  backdrop-filter: blur(1px);
  /* Applies the blur effect to the background */
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  color: #000000;
  font-size: 16px;
  font-family: 'redHatBold';
  font-weight: 800;
}

.emly-overlay-wtc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.1); */
  backdrop-filter: blur(1px);
  /* Applies the blur effect to the background */
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  color: #000000;
  font-size: 16px;
  font-family: 'redHatBold';
  font-weight: 800;
}

.emly-loading-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-top-color: #000;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.emly-bg-container {
  width: 100%;
  float: left;
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow: hidden;
}

.em-container {
  width: 100%;
  float: left;
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow: hidden;
}

.em-row {
  width: 100%;
  float: left;
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.em-center {
  margin: 0 auto;
  /* This will center the div horizontally */
  width: 80%;
  /* Adjust the width as needed */
  padding: 20px;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.em-col {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.emly-scrollable {
  overflow: auto;
  height: calc(100vh - 200px);
}

.emly-scrollable::-webkit-scrollbar {
  height: 11px;
  width: 11px;
}

.emly-scrollable::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

.emly-scrollable::-webkit-scrollbar-thumb {
  background: var(--themeColor);
  border-radius: 20px;
}

.emly-scrollable::-webkit-scrollbar-thumb:hover {
  background: var(--themeColor);
}

.emly-p10 {
  padding: 10px;
}

.emly-pR10 {
  padding-right: 10px;
}

.emly-m10 {
  margin: 10px;
}

.emly-p20 {
  padding: 20px;
}

.em-p20 {
  padding: 20px;
}

.em-p30 {
  padding: 30px;
}

.emly-m20 {
  margin: 20px;
}

.em-marR10 {
  margin-right: 10px !important;
}

.em-marL10 {
  margin-left: 10px !important;
}

.em-marR20 {
  margin-right: 20px !important;
}

.em-marL20 {
  margin-left: 20px !important;
}

.em-danger {
  background: #ff0000 !important;
}

.em-tbm15 {
  margin: 15px 0 15px 0;
}

.superset-dashboards {
  width: 100%;
  position: relative;
  display: flex;
  grid-gap: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 0px;
  overflow-x: hidden !important;
}

.em-card {
  position: relative;
  height: 257px;
  width: 32%;
  margin: 0px;
  border-radius: 20px;
  transition: box-shadow 0.2s ease-out;
  background-image: url(../src/images/card0superset2.png);
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: 100% 100%;
}

.em-card #delt {
  font-size: 15px;
  color: white;
  cursor: pointer;
  position: absolute;
  right: 11px;
  top: 13px;
}

.em-card .em-head {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 800;
  letter-spacing: 0.12px;
  color: white;
  align-self: center;
  font-family: redHatBold;
  text-align: start;
  width: 80%;
  white-space: pre;
  padding: 8px 17px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.em-card .em-footer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
  position: absolute;
  right: 17px;
  bottom: 20px;
}

.em-header {
  width: 100%;
  position: relative;
  font-family: 'redHatBold';
  padding: 0;
  margin: 0;
  font-size: 20px;
}

.em-btn {
  color: white;
  background: var(--themeColor);
  border: none;
  border-radius: var(--borderRadius) !important;
  width: auto;
  font-size: 13px;
  padding: 7px 17px;
  font-family: 'redHatBold';
  cursor: pointer;
  text-align: center;
  float: right;
  text-transform: capitalize;
}

.em-btn-disabled {
  border: 1px solid #8f9692 !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.em-btn.micr-b {
  color: white;
  margin: 30px;
  padding: 10px 120px;
}

.em-micr-ttl {
  font-family: 'redHatMedium';
  text-align: left;
  font-size: 12px;
  width: 631px;
}

.cancel-btn {
  color: var(--themeColor) !important;
  background: white !important;
  border: 1px solid var(--themeColor) !important;
  padding: 6px 17px;
}

.em-btn:active {
  color: var(--blackColor);
}

.em-btn[disabled] {
  background-color: #fff !important;
  border: 1px solid #8f9692 !important;
  color: #b2b8b5 !important;
  pointer-events: none;
}

.em-btn.disabled {
  background-color: #fff !important;
  border: 1px solid #8f9692 !important;
  color: #b2b8b5 !important;
  pointer-events: none;
}

.em-link-btn {
  color: #555;
  background: none;
  border: none;
  width: auto;
  font-size: 13px;
  padding: 0;
  text-decoration: underline;
  font-family: 'redHatBold';
  cursor: pointer;
  text-align: center;
  text-transform: capitalize;
}

.em-danger-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: relative;
  color: #ef3636;
  background-color: #fcd462;
  white-space: pre;
  position: relative;
  width: 100%;
  overflow: hidden;
  float: left;
  text-align: center;
  border-radius: 6px;
  font-family: 'redHatBold';
  padding: 5px 12px;
}

.em-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.em-col1 {
  width: 10%;
}

.em-col2 {
  width: 20%;
}

.em-col3 {
  width: 30%;
}

.em-col4 {
  width: 40%;
}

.em-col5 {
  width: 50%;
}

.em-col6 {
  width: 60%;
}

.em-col7 {
  width: 70%;
}

.em-col8 {
  width: 80%;
}

.em-col9 {
  width: 90%;
}

.em-col10 {
  width: 100%;
}

.em-bg-theme {
  background: var(--themeColor);
}

.em-sfont {
  font-size: 14px;
  font-weight: 700;
  font-family: 'redHatMedium';
}

.em-tc {
  text-align: center;
}

.em-bx {
  position: relative;
  border: 2px solid var(--themeColor);
  padding: 13px;
  border-radius: 13px;
}

.em-bx .tytl {
  font-family: 'redHatBold';
  position: absolute;
  left: 3px;
  background: #fff;
  font-size: 17px;
  top: -31px;
  color: var(--themeColor);
  padding: 0;
}

.em-overflow-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.cayley-paginate .em-link-btn[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.em-input {
  display: block;
  width: 100%;
  padding: 8px;
  font-size: 12px;
  line-height: 1.66667;
  color: #363636;
  background-color: #fff;
  background-image: none;
  border: 1px solid #5a5a5a1f;
  border-radius: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.em-tcomb-ms {
  display: block;
  width: 100%;
  font-size: 12px;
  line-height: 1.66667;
  color: #363636;
  background-color: #fff;
  background-image: none;
  border-radius: 8px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.em-f-left {
  float: left !important;
}

.em-f-right {
  float: right !important;
}

.em-positionItem {
  position: relative;
  width: auto;
  margin: 0;
  padding: 0;
}

.em-positionElement {
  position: relative;
  width: 50%;
  margin: 0;
  padding: 10px 0;
  min-height: 250px;
}

.em-positionElement.genAi {
  min-height: unset !important;
}

.em-positionElement.genAi.embtn {
  display: flex;
  justify-content: center;
  align-self: flex-end;
}

.em-positionElement.genAi.embtn .em-btn {
  width: -webkit-fill-available;
}

.help-sec.isPresent svg path:nth-child(1) {
  fill: var(--themeColor) !important;
  fill-opacity: 1 !important;
  stroke: var(--themeColor) !important;
  cursor: pointer;
}

.help-sec .popupMainContainer .popupMainContainer-all-content {
  padding: 0;
}

.help-sec .popupMainContainer .popupMainContainer-all-content.success div {
  display: flex;
  margin-top: 0 !important;
}

.help-sec.pdfViewer .popupMainContainer .popup-content {
  padding: 0;
}

.help-sec .popupMainContainer .popupMainContainer-all-content .currentOrg {
  display: none;
}

.help-sec .popupMainContainer .popupMainContainer-all-content .modalFooter-1 {
  margin-top: 0% !important;
}

.prview-dt-grid .react-grid-HeaderCell {
  pointer-events: none !important;
}

.em-warning {
  position: relative;
  color: #ef3636;
  background-color: #fcd462;
  width: 100%;
  overflow: hidden;
  float: left;
  text-align: left;
  font-family: 'redHatBold';
  padding: 10px;
}

.help-video-title {
  font-weight: 400;
  color: rgb(0, 136, 206);
  font-size: 15px;
}

.videoPlay-section .popupMainContainer-all-content {
  background-color: #000 !important;
}

.cronTextDeco {
  background: var(--themeColor);
  padding: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 1.25em;
}

.cronTextSubDeco {
  font-size: 1.12em;
  padding: 10px;
  background: #e6e6e6;
}

.em-help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #767676 !important;
  font-size: 12px !important;
}

.em-label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px !important;
  font-weight: 700;
}

@media only screen and (min-width: 1681px) {
  .cayleysider-btns {
    width: 2% !important;
  }
}

@media only screen and (max-width: 1329px) {
  .card.sp-project {
    width: 31%;
  }

  .keycloackerror {
    width: 75% !important;
  }
}

.tooltip-inner {
  font-size: 11px;
  font-family: 'redHatBold';
}

.cayley-icon-btn {
  padding: 0 7px;
}

/* Added Css for One Scroll */
.em-oneScroll {
  max-height: calc(100vh - 140px);
  overflow-y: auto;
  position: relative;
  width: 100%;
  padding: 5px 15px 5px 0px !important;
  scroll-behavior: smooth;
}

.em-oneScroll::-webkit-scrollbar {
  height: 5px !important;
  width: 10px !important;
}

/* Track */

.em-oneScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */

.em-oneScroll::-webkit-scrollbar-thumb {
  background: var(--themeColor);
  border-radius: 20px;
}

.em-row.g-c-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0 0;
  gap: 10px;
}

h1.or-label {
  align-items: center;
  color: #7b7b7b !important;
  display: flex;
  font-family: bold;
  font-size: 18px;
  gap: 10px;
  justify-content: center;
  margin: 0 0 10px;
  padding: 0 2px;
  width: 100%;
}

h1.or-label span {
  border: 1px solid rgba(144, 163, 191, 0.3);
  width: 45%;
}

.em-btn.swg-icon {
  background: white;
  border: 1px solid #d9d9d9;
  color: #363636;
  padding: 10px 10px;
  width: 100%;
}

/* NOT FOUND Component */

.nf-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.nf-message {
  padding: 20px;
  text-align: center;
}

.nf-message h2 {
  margin-bottom: 10px;
  font-family: 'redHatBold';
}

.nf-message p {
  margin: 0;
  font-family: 'redHatMedium';
  font-size: 16px;
}

.nf-message-container .em-btn {
  float: none;
  margin: 15px;
}

.uber-ser-not-up button,
.uber-ser-not-up .ac-btn,
.BatchAppSection.uber-ser-not-up input,
.BatchAppSection.uber-ser-not-up .css-2b097c-container,
.uber-ser-not-up .vi-ac-co {
  pointer-events: none;
  opacity: 0.5;
}

.restore-link {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffbb33;
  float: left;
  padding: 5px;
  margin-top: 5px;
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.restore-link .em-btn {
  float: none;
  margin-left: 5px;
  color: #000000 !important;
  opacity: unset;
  font-family: 'redHatBold';
  pointer-events: all;
  padding: 0;
  text-decoration: underline;
  background: none !important;
}

.restore-link .restore-svg {
  width: 28px;
  height: 28px;
  fill: #ffffff;
  position: absolute;
  left: -13px;
}

.restore-link span {
  margin-left: 20px;
}

.setting-nav-sub-menu .vertical-tabs-pf-tab.active {
  background: var(--themeColor);
  color: #fff;
  width: 92%;
  margin-right: 14px;
  padding: 5px;
}

.em-pad15Top .popupMainContainer-all-content .mp-content {
  margin-top: 15px !important;
}
.em_down {
  display: flex;
  background-color: #fcd462;
  align-items: center;
  justify-content: space-between;
  width: calc(100vw - 200px);
  min-height: 35px;
  overflow: hidden;
  float: left;
  text-align: center;
  border-radius: 6px;
  font-family: 'redHatBold';
  padding: 5px 12px;
  gap: 10px;
  margin: 0;
  position: fixed;
  z-index: 9999;
}

.em_down.success {
}

.em_down.error {
}
.em_down.warning {
}

.right-body-panel.collapsed .em_down {
  width: calc(100vw - 80px);
}
.em_content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 95%;
}

.em_down img {
  width: 25px;
}

.em_down_message {
  display: -webkit-box;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-family: 'redHatMedium';
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: normal;
  font-weight: 600;
  white-space: nowrap;
}

.em_down.success .em_down_message {
  color: #000000 !important;
}

.em_down.error .em_down_message {
  color: #ef3636 !important;
}
.em_down.warning .em_down_message {
  color: #000000 !important;
}

.em_down_close {
  color: #000000;
  cursor: pointer;
  display: flex;
  gap: 3px;
  align-items: center;
  flex-wrap: nowrap;
  width: 5%;
}

.em_down .fa {
  font-size: 15px;
}

.em_down_close .fa {
  font-size: 12px;
}

.em_down .fa.success {
  color: green;
}

.em_down .fa.error {
  color: red;
}

.em_down .fa.warning {
  color: sandybrown;
}

.popupMainContainer {
  position: fixed !important;
  z-index: 100 !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.1);
}

.popupMainContainer.res-Op-card {
  z-index: 1001 !important;
}

.popupMainContainer.res-Op-card .popupMainContainer-all-content {
  padding: 0% !important;
  border: none !important;
}

.popup-content {
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding: 20px 0;
}

.popupMainContainer .popupMainContainer-all-content {
  height: auto;
  position: relative !important;
  background-color: #fefefe;
  padding: 20px;
  text-align: start !important;
  border: 1px solid #8888884d !important;
  border-radius: 14px;
  display: flex !important;
  align-content: center !important;
  justify-content: center;
  flex-direction: column !important;
}
.popupMainContainer .danger {
  box-shadow: 0px 0px 0px 0.2px red;
}

.popupMainContainer .popupMainContainer-all-content .modalHeader .header-icons {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.if-full-screen .fulscreen-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.if-full-screen span{
  position: absolute;  
  width: max-content;
  text-align: center;
  background: #00000094;
  color: white;
  padding: 5px;
  cursor: pointer;
  z-index: 9;
  border-radius: 8px;
  font-size: 13px;
}

.if-full-screen .fulscreen-icon span.exit-fs{
  margin: 60px 0 0 0 ;
}

.if-full-screen .fulscreen-icon span.set-fs{
  margin: 60px 0 0 0 ;
}

.popupMainContainer .popupMainContainer-all-content .modalHeader .pdf-style {
  display: flex;
  justify-content: flex-end;
  color: #000;
  flex: 1px;
  background-color: red;
}

.popupMainContainer .popupMainContainer-all-content .mp-content{
  max-height: calc(100vh - 130px); 
  width: 100%;
}

.popupMainContainer .popupMainContainer-all-content .mp-content.em-scroll{
  overflow-y: auto;
}

.popupMainContainer .popupMainContainer-all-content .modalHeader .fulscreen-icon {
  cursor: pointer !important;
  color: #ffff !important;
  border: 0 !important;
  width: 25px !important;
  border-radius: 30px !important;
  height: 25px !important;
}

.popupMainContainer .popupMainContainer-all-content .modalHeader .fulscreen-icon svg.fa-expand path:nth-child(1) {
  height: -webkit-fill-available;
  fill: #2d3748 !important;
}

.popupMainContainer .popupMainContainer-all-content .modalHeader .fulscreen-icon svg.fa-compress path:nth-child(1) {
  height: -webkit-fill-available;
  fill: #2d3748 !important;
}

.popupMainContainer .popupMainContainer-all-content .modalHeader .fulscreen-icon .svg-inline--fa {
  height: -webkit-fill-available;
}

.popupMainContainer .popupMainContainer-all-content .modalHeader .close-button {
  cursor: pointer !important;
  background: #2d3748 !important;
  color: #ffff !important;
  border: 0 !important;  
  border-radius: 5px !important;  
}

.popupMainContainer .popupMainContainer-all-content .modalHeader .close-button .fa.fa-times {
  font-size: 22px !important;
}

.popupMainContainer .popupMainContainer-all-content .modalFooter-1 {
  flex-wrap: wrap !important ;
  display: flex !important;
  justify-content: center !important;
  margin-top: 17px !important;
}

.popupMainContainer .popupMainContainer-all-content .modalFooter-1 #invite-user-1 {
  font-weight: bold !important;
  color: #ffff !important;
}
.popupMainContainer .popupMainContainer-all-content .modalFooter {
  flex-wrap: wrap !important ;
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 17px !important;
}

.popupMainContainer .popupMainContainer-all-content .modalFooter #invite-user {
  /* font-weight: bold !important; */
  color: #ffff !important;
  font-family: 'redHatMedium';
}

.popupMainContainer .popupMainContainer-all-content .modalFooter #cancel {
  font-weight: bold !important;
  background: #ffff !important;
  font-family: 'redHatBold';
}

.popupMainContainer .dangerInvite {
  background: red !important;
}
.popupMainContainer .successInvite {
  background: var(--themeColor) !important;
}

.popupMainContainer .disabledInvite {
  background: lightgray !important;
  cursor: unset;
}

.modalMainContainer .dangerCancel {
  border: 1px solid red !important;
  color: red !important;
}

.popupMainContainer .successCancel {
  border: 1px solid var(--themeColor) !important;
  color: var(--themeColor) !important;
}

.popupMainContainer .popupMainContainer-all-content .modalFooter button {
  padding: 8px !important;
  border: none !important;
  width: 48% !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center;
  height: 40px;
}

.popupMainContainer .popupMainContainer-all-content .modalFooter-1 button {
  padding: 8px !important;
  border: none !important;
  width: 48% !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center;
  height: 40px;
}

.popupMainContainer .currentOrg {
  padding: 10px 0 !important;
  font-family: 'redHatBold';
}

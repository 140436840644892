.project-cr-form .project-button {
  color: white;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  width: 30%;
  height: 45px;
  background-color: var(--themeColor);
  font-family: 'redHatMedium' !important;
}

.project-cr-form .project-button-continue-disabled {
  color: white;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  width: 30%;
  height: 45px;
  background-color: lightgrey;
  border-color: var(--themeColor);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  pointer-events: none;
}

.project-cr-form .submit-buttonn {
  background-color: #5ccbb5;
  display: block;
  align-self: center;
  border: none;
  width: 80%;
  color: #fff;
  font-size: 16px;
  height: 60px;
  border-radius: 5px;
}

.project-cr-form .continue-button {
  color: white;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  width: 17%;
  height: 38px;
  background-color: #2d3748;
  border-color: #2d3748;
  margin-left: 20px;
}

.project-cr-form .cancel-button {
  color: grey;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  width: 30%;
  height: 35px;
  background-color: #fff;
  border-color: lightgrey;
}

.project-cr-form input[type='text'] {
  width: 100%;
  padding: 10px;
  margin: 0px 0 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none !important;
  font-size: 16px;
}

/* Description textarea style */
.project-cr-form textarea {
  width: 100%;
  padding: 10px;
  margin: 0px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none !important;
  font-size: 16px;
}

.project-cr-form .css-1wa3eu0-placeholder {
  color: '#fff';
}

.project-cr-form .css-1wa3g1l-icon {
  color: red;
  /* Change the color to the desired color */
}

.project-cr-form .custom-select-label1 {
  position: absolute;
  margin-top: 14px;
  /* Adjust this value as needed */
  left: 0;
  background-color: var(--themeColor) !important;
  /* Match your background color */
  padding: 0 5px;
  font-weight: bold;
  z-index: 1;
  margin-left: 20px;
  color: #fff;
  font-size: 12px;
}

.project-cr-form textarea {
  resize: none;
}

.project-cr-form label {
  color: #000;
  font-weight: normal !important;
  margin-bottom: 0px !important;
}

.project-cr-form {
  padding: 20px;
}

.project-cr-form .group-info {
  gap: 2%;
  padding-top: 15px;
  justify-content: flex-start;
}

.project-cr-form .group-info .f-switch {
  margin-bottom: 10px;
}

.project-cr-form .group-info .f-switch .flp-sw {
  display: flex;
  gap: 15px;
  align-items: center;
  width: max-content;
}

.project-cr-form .group-info .f-switch .flp-sw span {
  font-size: 15px;
  font-family: 'redHatBold';
}

.project-cr-form .projectutils {
  width: 40% !important;
}

.project-group-help {
  position: absolute;
  font-family: 'redHatBold';
  right: 0;
  float: right;
  padding-top: 5px;
}

.project-cr-form .group-info .items {
  width: 100%;
  position: relative;
}

.project-cr-form .group-info .css-1uccc91-singleValue {
  color: #000000 !important;
  font-size: 16px !important;
  text-transform: capitalize;
}

.project-cr-form .projectutils.group-sets {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
}

.project-cr-form .group-info .projectutils .group-refresh-button {
  background: none !important;
  outline: none !important;
  border: none !important;
  padding: 0;
  color: grey;
}

.project-cr-form .group-info .projectutils .group-refresh-button .fa {
  font-size: 25px;
}
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'redHatMedium';
}

.graph {
  border: 1px solid #eee;
  min-width: 300px;
  min-height: 400px;
}

.padtb-15 {
  padding: 15px 0px;
}

.center-50 {
  margin: auto;
  width: 50%;
}

.center-60 {
  margin: auto;
  width: 60%;
}

.center-70 {
  margin: auto;
  width: 70%;
}

a.isDisabled {
  color: currentColor;
  display: inline-block;
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
  text-decoration: none;
}

div.list-view-pf-additional-info {
  width: auto;
  flex: 0 0 auto;
}

table.csv-html-table {
  border: 1px solid #ccc;
}

.react-json-view div.variable-row {
  word-break: break-all;
}

.filepond--file-info .filepond--file-info-sub {
  font-size: 0.725em;
  opacity: 0.8;
}

.filepond--file-info .filepond--file-info-main {
  font-size: 0.85em;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mt-3 {
  margin-top: 3em;
}

.mt-4 {
  margin-top: 4em;
}

.mt-5 {
  margin-top: 5em;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.mb-3 {
  margin-bottom: 3em;
}

.mb-4 {
  margin-bottom: 4em;
}

.mb-5 {
  margin-bottom: 5em;
}

.filepond--root {
  font-size: 1.2rem;
}

.filepond--file-action-button.filepond--file-action-button svg {
  width: 100% !important;
}

ol.breadcrumbs-pf-title,
ol.breadcrumbs-pf-title li:active {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

span.fa-0x5x {
  font-size: 11px !important;
  vertical-align: 0px !important;
  padding: 0 9px 0 3px;
}

.list-view-pf .list-group-item-text {
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-all;
}

div.list-view-pf .list-group-item-heading {
  white-space: pre-wrap;
  text-overflow: inherit;
  word-break: break-all;
}

.btn-pad-0 button,
.btn-pad-0 a {
  padding-left: 0px;
}

.progress-bar-danger {
  background-color: #3f9c35 !important;
}

.progress-bar-warning {
  background-color: #39a5dc !important;
}

.progress-bar-success {
  background-color: #c00 !important;
}

.modal-75 .modal-dialog {
  width: 75% !important;
}

.jsonOdd {
  background: #eee;
}

.btn-link:hover,
.btn-link:focus {
  outline: none;
}

.center-align-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.appV-actions-panel {
  margin-top: 20px;
  margin-bottom: 20px;
}

.appV-app-runs {
  margin-top: 0px;
  margin-bottom: 50px;
}

.appV-actions-panel .appV-actions-panel-data {
  height: 60px;
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
}

.appV-actions-panel-rt .list-group-item:hover,
.appV-actions-panel-rt .list-group-item:focus,
.appV-actions-panel-rt .list-group-item:active {
  background-color: #ffffff;
}

.appV-actions-panel-rt .list-view-pf-main-info {
  padding-bottom: 0px;
  padding-top: 10px;
}

.appV-actions-panel-rt {
  border-bottom: 1px solid #d1d1d1;
  box-shadow: 0 1px 0px rgba(3, 3, 3, 0.045);
}

.appV-actions-panel-rt .list-group-item.list-view-pf-expand-active {
  background-color: #f5f5f5;
}

.appV-actions-panel.list-group .list-group-item:hover {
  background-color: #fff;
}

.schedule-typeahead-ui .rbt-input {
  font-size: 14px;
  height: 29px;
  border-radius: 3px;
}

.setting-nav-sub-menu .caret::before {
  content: '';
  color: #303338;
  display: inline-block;
  margin-left: 20px;
  font-size: 3.62em;
  position: absolute;
}


.setting-nav-sub-menu-res-title a {
  font-weight: bold;
  color: #555;
}

.setting-nav-sub-menu-res-title {
  margin-bottom: 12px;
}

.vertical-tabs-pf-tab.active>a::before{
  background: transparent !important;
}

.vertical-tabs-pf-tab.active-descendant a {
  font-weight: 100;
}

.vertical-tabs-pf-tab > a {
  padding: 3px 6px 3px 1px;
}

.vertical-tabs-pf-tab.active.active-descendant a {
  color: #fff;
  position: relative;
}

.vertical-tabs-pf-tab.active.active-descendant a .setting-nav-sub-menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#vertical-settings-tabs {
  direction: rtl;
  text-align: left;
  overflow: auto;
  height: calc(100vh - 250px);
}

.vertical-tabs-pf-tab.setting-nav-sub-menu-res-title > a:hover,
.vertical-tabs-pf-tab.setting-nav-sub-menu-res-title > a:focus {
  color: #555;
  text-decoration: none;
}

.vertical-tabs-pf-tab.setting-nav-sub-menu > a:hover,
.vertical-tabs-pf-tab.setting-nav-sub-menu > a:focus {
  color: #0088ce;
  text-decoration: none;
}

#ace-editor-code-editor.ace_editor {
  height: inherit !important;
}

/* 
#ace-editor-code-editor.ace_editor .ace_content{
    height:inherit  !important
} */

.vertical-tabs-pf-tab.active > a::before {
  content: '';
}

.alpaca-form legend {
  font-size: 14px;
  font-weight: bold;
  color: #555;
  margin-top: 1.2em;
}

form.alpaca-form div.alpaca-field.alpaca-top {
  height: calc(100vh - 330px);
  overflow: auto;
}

div.form-group.has-error div.css-vj8t7z {
  border-color: #c00;
}

div.css-vj8t7z,
div.css-2o5izw {
  min-height: 25px;
  border-radius: 0px;
}

div.css-vj8t7z .css-1g6gooi,
div.css-2o5izw .css-1g6gooi {
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
}

div.css-vj8t7z .css-1ep9fjw,
div.css-2o5izw .css-1uq0kb5 {
  padding: 3px;
}

div.css-vj8t7z .css-xwjg1b,
div.css-2o5izw .css-xwjg1b {
  margin: 0px;
  margin-right: 1px;
  padding-left: 1px;
}

div.css-vj8t7z .css-12jo7m5,
div.css-2o5izw .css-12jo7m5 {
  padding: 0px;
}

.myJobs-search .form-group {
  width: -webkit-fill-available;
  padding-left: 0px;
}

.myJobs-search .form-group .form-control {
  border-bottom: 1px solid #c6d1d4;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  box-shadow: none;
  font-size: 15px;
}

.myJobs-search .form-group .form-control:hover,
.myJobs-search .form-group .form-control:focus {
  border-color: #7dc3e8;
}

.toolbar-pf .myJobs-search .form-group button[disabled].btn-link span {
  color: #777;
}

.toolbar-pf .myJobs-search .form-group button.btn-link span {
  color: #39a5dc;
}

.alert-info-run-status span {
  margin-top: 5px;
}

.appV-actions-panel-data .alert-info-run-status .pficon.pficon-info {
  display: none;
}

.appV-actions-panel-data .alert-info-run-status {
  padding-left: 20px;
}

.appV-actions-panel-data button {
  margin-top: 15px;
}

.centre-loading div {
  margin-left: auto;
  margin-right: auto;
}

.quickTipsBox {
  list-style: none;
}

.quickTipsBox li {
  font-size: 1.3em;
  font-weight: 600;
  font-family: 'redHatMedium';
  margin: 7px;
  color: #555;
}

.quickTipsBox li span {
  margin-right: 5px;
  font-size: 1.3em;
}

.list-view-pf .list-group-item.noRunsNoHg:hover {
  background: #fff;
}

div.iter-fail-msgs {
  margin-top: 20px;
  word-break: break-word;
  white-space: pre-wrap;
}

.tryFormDiv fieldset,
.tryFormResDiv {
  max-height: calc(100vh - 310px);
  overflow: auto;
}

.form-group.form-group-batch label.control-label {
  display: block;
  margin-bottom: 2px;
}

.form-group.form-group-batch .radio {
  display: initial;
  margin-right: 20px;
}

.annotator-modal {
  width: 100vw;
  margin: 0px;
  padding: 0px !important;
}

.centre-loading-main {
  height: 200px;
}

.annotator-modal .pdf-title {
  position: absolute;
  padding: 5px;
  text-align: center;
  color: #eee;
  margin-left: 20%;
  width: 35%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.annotator-modal .pdf-autosave {
  position: absolute;
  padding: 5px;
  text-align: center;
  color: #ddd;
  margin-left: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.toast-notifications-list-pf {
  top: 110px;
}

.buildAppModal .radio input[type='radio'] {
  position: absolute;
}

.buildAppModal .radio label {
  position: relative;
}

.buildAppModal .help-block {
  text-align: left;
  font-size: 12px;
}

.buildAppModal .build-app-embed-name {
  font-size: 15px;
}

.rt-fixed-help-dialog .modal-dialog {
  padding: 0px;
}

.quick-help-img {
  height: 40px;
  margin-right: 5px;
  border: 1.2px solid #a59f9f;
  background-color: #b6bac13b;
  cursor: pointer;
}

.rt-fixed-help-dialog button.quick-help-button span,
.rt-fixed-help-dialog a {
  font-family: 'redHatMedium';
  font-size: 1.2em;
  width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-align: left;
}

.rt-fixed-help-dialog button.quick-help-button {
  display: flex;
  width: -webkit-fill-available;
  text-decoration: none;
}

@media (min-width: 768px) {
  .rt-fixed-help-dialog .modal-sm {
    width: 400px;
  }
}

.rt-fixed-help-dialog .modal-header {
  background-color: #f3f8fe;
  padding: 15px 18px;
}

.rt-fixed-help-dialog .modal-header .modal-title {
  font-size: 1.2em;
  color: #555;
}

.rt-fixed-help-dialog .modal-body ul {
  margin-top: 35px;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.mb-3 {
  margin-bottom: 3em;
}

.col-center-content {
  margin-left: auto;
  margin-right: auto;
  float: none !important;
}

@media (min-width: 992px) {
  .wizard-pf {
    padding: 60px 0;
    width: 900px;
  }
}

@media (min-width: 768px) {
  .welcome-user-modal .modal-dialog {
    width: 600px;
    margin: 50px auto;
  }
}

.btn-link:hover,
.btn-link:focus {
  outline: none !important;
}

button.rt-fixed-help-dialog-qh-btn {
  text-decoration: none !important;
}

.btn-lg,
.btn-group-lg > .btn,
.btn {
  border-radius: 2px;
}

.modal-lt-header.modal-header {
  background-color: #f3f8fe;
  padding: 15px 18px;
}

.modal-lt-header.modal-header .modal-title {
  font-size: 1.2em;
  color: #555;
}

.pg-viewer-wrapper {
  overflow-y: auto !important;
  height: calc(100vh - 250px) !important;
  min-height: calc(100vh - 250px);
}

.pg-viewer-wrapper .photo-viewer-container,
.pg-viewer-wrapper .photo-viewer-container img {
  width: auto !important;
  height: inherit !important;
}

.react-data-grid-display {
  min-height: auto;
}

.react-data-grid-display .react-grid-Canvas {
  overflow: auto auto !important;
}

.no-preview-container {
  min-height: calc(100vh - 270px);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  flex-direction: column;
}

.sample-data-preview-container {
  min-height: auto;
  overflow: auto;
  height: auto;
}

.downloadDSIMsg li {
  list-style: none;
  display: inline;
  text-decoration: underline;
}

.downloadDSIMsg {
  list-style: none;
  display: inline;
  text-decoration: underline;
}

.center-explore-50 {
  margin: 2em auto;
  width: fit-content;
}

.page-centre-error-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px);
}

.tab-auto-w a {
  width: auto !important;
}

.preview-centre-loader {
  display: flex;
  justify-content: center;
  height: calc(100vh - 220px);
  align-items: center;
}

.username-tr {
  color: #d1d1d1;
  line-height: 1;
  max-height: 58px;
  padding: 16px 12px;
  /* font-size: 16px; */
  font-size: 14px;
  text-transform: capitalize;
}

.username-tr i {
  font-size: 17px;
}

.user-tr-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

body.cards-pf {
  background: #f5f7fb;
}

.text-orange {
  color: #ff5722;
}

@media (min-width: 1110px) {
  .modal-xl {
    width: 1170px;
  }
}

.raw-setting-status {
  float: right;
  width: 60%;
  margin: 20px 0 0;
}

.raw-setting-container {
  float: left;
  width: 100%;
  margin-bottom: 0px;
}

.move-broom {
  top: -10px;
  position: absolute;
  -webkit-animation: linear infinite alternate;
  -webkit-animation-name: run;
  -webkit-animation-duration: 1s;
}

@-webkit-keyframes run {
  0% {
    left: 0;
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    left: 0;
  }
}

.file-up-element {
  width: 100%;
  float: left;
  margin: 5px auto;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.file-bdy-sec {
  width: 25%;
  font-family: 'redHatMedium';
  font-weight: 700;
}

.file-progress-sec {
  width: 65%;
}

.file-actions-sec {
  display: flex;
  justify-content: space-evenly;
  width: 10%;
}

.file-actions-sec button {
  line-height: normal;
  padding: 5px;
}

.modal.right-side-modal-pf .modal-dialog {
  margin: 0px 0 0 10px;
}
.modal.right-side-modal-pf .modal-dialog .modal-content {
  height: calc(100vh) !important;
}

.start-org-creation {
  width: 100%;
  background: #f3f3f3e8;
  overflow-y: auto !important;
  height: 100vh !important;
  background-color: rgb(255, 255, 255);
}

.start-org-creation .em-org-cr-flex {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.start-org-creation .em-org-cr-flex .start-org-content {
  height: -webkit-fill-available;
}

.start-org-creation .em-org-cr-flex .start-org-content h3 {
  font-size: 17px;
  margin: 0;
}

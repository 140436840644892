.showShadow .popupMainContainer-all-content {  
  border-radius: 8px !important;
  box-shadow: 2px 3px 8px 1px grey;
}

.outside-wrapper {
  position: fixed !important;
  z-index: 100 !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.inside-wrapper {
  height: 100%;
  position: relative;
  text-align: start;
  border: 1px solid hsla(0, 0%, 53.3%, .3) !important;
  border-radius: 14px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  background: rgb(0 0 0 / 34%);
  border-radius: 0;
}

.rs-em-sc-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rs-em-sc-wrapper .rs-em-sc-content {
  width: 700px;
  position: relative;
  background: #efefef;
  border-radius: 8px;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance {
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 20px;
  flex-direction: row;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-image svg {
  width: 40px;
  height: 40px;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content {
  width: 100%;
  float: left;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-title {
  color: black;
  font-family: 'redHatBold';
  font-size: 22px;
  width: 100%;
  float: left;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-progress {
  float: left;
  width: 100%;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-progress span {
  float: right;
  font-family: 'redHatBold';
  color: gray;
  font-size: 14px;
  display: flex;
  align-items: baseline;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-progress .rsc-progressLine {
  float: left;
  width: 100%;
  background: gray;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-progress .rsc-info {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px 0 0;
  color: gray;
  font-size: 13px;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-description {
  width: 100%;
  float: left;
  font-size: 12px;
  font-family: 'redHatMedium';
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-description .rsc-vmore {
  width: 100%;
  float: left;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-vmore .rsc-line {
  width: 100%;
  float: left;
  border: 1px solid lightgray;
  margin: 15px 0 2px;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-vmore .rsc-ul {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-vmore .rsc-ul li {
  list-style: none;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-vmore .rsc-ul li span {
  font-family: 'redHatBold';
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-vmore .rsc-note {
  width: 100%;
  float: left;
  margin: 20px 0 0;
  font-size: 10px;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-vmore .rsc-note span {
  font-family: 'redHatBold';
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-amore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: left;
  width: 100%;
  margin: 10px 0;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-amore .rsc-link {
  border: none;
  background: transparent;
  border-bottom: 1px solid #80808061;
  border-radius: 0px !important;
  color: darkgray;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-amore .rsc-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-amore .rsc-actions button {
  border-radius: 8px;
  width: fit-content;
  color: #000000;
  border: none;
  padding: 2px 18px;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-amore .rsc-actions .rsc-cancel {
  background: #efefef;
  border: 1px solid darkgray;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-amore .rsc-actions .rsc-restore {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: 1px solid #ffffff;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-amore .rsc-actions .rsc-restore svg {
  width: 10px;
  height: 10px;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-amore .rsc-actions .rsc-restore:hover svg {
  fill: green;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-support {
  font-size: 10px;
  text-align: center;
  width: 100%;
  float: left;
  margin: 10px 0 0;
}

.rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-support a {
  color: #4285f4;
}

.rsc-dots-container {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.rsc-dots-container .rsc-dot {
  margin: 0 2px;
  width: 5px;
  height: 5px;
  background-color: black;
  border-radius: 50%;
  opacity: 0;
  animation: blink 1.5s infinite;
}

.rsc-dot:nth-child(1) {
  animation-delay: 0s;
}

.rsc-dot:nth-child(2) {
  animation-delay: 0.3s;
}

.rsc-dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes blink {

  0%,
  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.rsc-load {
  width: 24px;
  height: 24px;
  border-width: 3px;
  border-style: dashed solid solid dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rsc-rotation 1s linear infinite;
}

.rsc-load::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 11px;
  top: 16px;
  border: 8px solid transparent;
  transform: rotate(-40deg);
}

@keyframes rsc-rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.inside-wrapper .rs-em-sc-wrapper .rs-em-sc-content .rsc-instance .rsc-content .rsc-amore {
  justify-content: end !important;
}